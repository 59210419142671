import React, { useContext, useEffect, useState } from 'react';
import { AppSettings } from '../../config/app-settings';
import Loader from '../pages/loader';
import ReusableTable from '../../components/other/ReuseableTable';
import { useDispatch, useSelector } from 'react-redux';
import { listGroups } from '../../redux/apis/authApi';
import EditCreateGroups from '../../components/users/EditCreateGroups';
import { can } from '../../helpers/auth';
import NoPermission from '../pages/noPermission';
import { toast } from 'react-toastify';
import { clearError, clearSuccess } from '../../redux/slices/permissionsSlice';

function Groups() {
	
    const dispatch = useDispatch()
	const { isLoading, groups, error, success } = useSelector(state => state.permissions)
	const [edit, setEdit] = useState(false)
	const [create, setCreate] = useState(false)
	const [holder, setHolder] = useState()

	useEffect(() => {
		if (!groups) {
			dispatch(listGroups())
		}
	}, [])

	let columns = [
		{ title: "ID", dataIndex: "id", key: "id", sorter: true },
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			sorter: true,
			search: true,
		},
		
	];
	if(can("change_group")){
		columns.push({
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<div className='flex gap-2'>
					<button className='btn btn-outline-theme' onClick={() => handleEdit(record)}>Edit</button>
				</div>
			),
		})
	}

	const handleEdit = (record) => {
		setHolder(record)
		setEdit(true)
	};
	

    const context = useContext(AppSettings);
	useEffect(() => {
		context.setAppHeaderNone(false);
		context.setAppSidebarNone(false);
		context.setAppContentClass('');
		context.setAppThemePanal(true)

		return function cleanUp() {
			context.setAppHeaderNone(true);
			context.setAppSidebarNone(true);
			context.setAppContentClass('p-0');
			context.setAppThemePanal(false)
		};
	}, []);

	useEffect(()=>{
        if ( !error && !isLoading) {
            return
        }
        toast.error(error,{ onclose: ()=>{dispatch(clearError())}})
    },[error, isLoading])

	
	useEffect(() => {
		if (success) {
			toast.success("Action successfuly", { onclose: () => { dispatch(clearSuccess()) } })
		}
	}, [success])

	if (!can("view_group")) {
        return <NoPermission />
    }
	return (
		<div className='w-full flex flex-col'>
			<Loader isLoading={isLoading} />
			{edit ? <EditCreateGroups state={"edit"} open={edit} setOpen={setEdit} group={holder} /> : null}
			{create ? <EditCreateGroups state={"create"} open={create} setOpen={setCreate} group={null} /> : null}
			<button className='btn btn-outline-theme ml-auto' onClick={() => setCreate(true)}>Add New Groups</button>
			<br/>
			{groups ?
				<ReusableTable
					data={groups}
					columns={columns}
				/>
				: null
			}
		</div>
	)
}

export default Groups;