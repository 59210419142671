import { Select } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listcountry } from '../redux/apis/utilsApi'
import { toast } from 'react-toastify'
import { clearError } from '../redux/slices/utilsSlice'

const CountriesDropdown = ({ onChange, onBlur, value }) => {
    const dispatch = useDispatch()
    const { contries, isLoading, error } = useSelector(state => state.utils)
    useEffect(() => {
        if (!contries) {

            dispatch(listcountry({
                page: '*',
                ordering: 'country_name',
                fields: 'id,country_name'
            }))
        }
    }, [])

    
	useEffect(()=>{
        if ( !error && !isLoading) {
            return
        }
        toast.error(error,{ onclose: ()=>{dispatch(clearError())}})
    },[error, isLoading])


    return (
        <div className=" mb-3">
            <label className="" >Country</label>
            <br />
            <Select
                name='country'
                showSearch
                style={{
                    width: "100%"
                }}
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                defaultValue={value}
                value={value}
                onChange={e => onChange("country", e)}
                onBlur={onBlur}
                loading={isLoading}
                options={contries?.map(x => {
                    return { label: x.country_name, value: x.id }
                })}
            />
        </div>
    )
}

export default CountriesDropdown