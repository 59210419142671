import axiosInstance from '../../helpers/conf'
import { createAsyncThunk } from '@reduxjs/toolkit';


export const listPlatforms = createAsyncThunk(
  'platform/listPlatforms',
  async (params) => {
    const response = await axiosInstance.get(`platforms/?expand=user,last_updated_by`, {params:{...params}});
    return response.results;
  }
);
export const createPlatform = createAsyncThunk(
  'platform/createPlatform',
  async (data) => {
    const response = await axiosInstance.post(`platforms/?expand=user,last_updated_by`, data);
    return response;
  }
);
export const updatePlatform = createAsyncThunk(
  'platform/updatePlatform',
  async ({data, id}) => {
    const response = await axiosInstance.patch(`platforms/${id}/?expand=user,last_updated_by`, data);
    return response;
  }
);
