import React, { useContext, useEffect } from 'react';
import { AppSettings } from '../../config/app-settings';
import { useDispatch, useSelector } from 'react-redux';
import { listPlatforms } from '../../redux/apis/platformApi';
import LinksInsertion from '../../components/LinkInsertion/LinksInsertion';
import TextAreaInsertion from '../../components/LinkInsertion/TextAreaInsertion';
import Loader from '../pages/loader';
import { can } from '../../helpers/auth';
import NoPermission from '../pages/noPermission';

function LinkInsertionYoutube() {
	const dispatch = useDispatch()
	const { platforms, isLoading } = useSelector(state => state.platform)

	useEffect(() => {
		
			dispatch(listPlatforms({
				platform_name__icontains: 'Youtube',
				page: '*',
				ordering: '-id',
				fields: ['id'].join(',')
			}))
			
	}, [])


	const context = useContext(AppSettings);
	useEffect(() => {
		context.setAppHeaderNone(false);
		context.setAppSidebarNone(false);
		context.setAppContentClass('');
		context.setAppThemePanal(true)

		return function cleanUp() {
			context.setAppHeaderNone(true);
			context.setAppSidebarNone(true);
			context.setAppContentClass('p-0');
			context.setAppThemePanal(false)
		};
	}, []);

	if (!can("add_videourl")) {
        return <NoPermission />
    }
	
	return (
		<div className='flex flex-col gap-8'>
			<Loader isLoading={isLoading}/>
			{platforms ? <>
				<LinksInsertion
					platform_name="Youtube"
					platformId={platforms[0]?.id}
					matchText={[/youtube\.com/, /youtu\.be/]}
				/>
				<TextAreaInsertion
					platform_name="Youtube"
					platformId={platforms[0]?.id}
					matchText={[/youtube\.com/, /youtu\.be/]}
				/>
			</> : null}
		</div>
	)
}

export default LinkInsertionYoutube;