import { Select } from 'antd'
import React from 'react'

const CampaignStatusDropdown = ({ onChange, onBlur, value }) => {
    let options = [
        {
            label: 'Active',
            value: true
        },
        {
            label: 'Inactive',
            value: false
        }
    ]
    return (
        <div className=" mb-3">
            <label className="" >Campaign Status</label>
            <Select
                name='campaign_status'
                style={{
                    width: "100%"
                }}
                defaultValue={value}
                value={value}
                onChange={e => onChange("campaign_status", e)}
                onBlur={onBlur}
                options={options}
            />
        </div>
    )
}

export default CampaignStatusDropdown