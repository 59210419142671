import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select } from 'antd'
import { listGroups } from '../redux/apis/authApi'
import { clearError } from '../redux/slices/permissionsSlice'
import { toast } from 'react-toastify'

const GroupsDropdown = ({ onChange, onBlur, value, multi = true }) => {
    const dispatch = useDispatch()
    const { groups, isLoading, error } = useSelector(state => state.permissions)
    let options = []
    useEffect(() => {
        if (!groups) {

            dispatch(listGroups({
                page: '*',
                ordering: 'name',
                fields: 'id,name'
            }))
        }
    }, [])
    if (groups) {
        options = groups.map((item) => {
            return {
                label: item.name,
                value: item.id
            }
        })
    }


    useEffect(() => {
        if (!error && !isLoading) {
            return
        }
        toast.error(error, { onclose: () => { dispatch(clearError()) } })
    }, [error, isLoading])

    return (
        <div className=" mb-3">
            <label className="" >Groups</label>
            <br />
            <Select
                mode={multi ? "multiple" : ""}
                allowClear
                style={{
                    width: '100%',
                }}
                loading={isLoading}
                placeholder="Please select"
                onBlur={onBlur}
                value={value}
                onChange={(e) => onChange("groups", e)}
                options={options}
            />
        </div>
    )
}

export default GroupsDropdown