import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { Button, Modal } from 'antd';
import PlatformsDropdown from '../PlatformsDropdown';
import { updateCampaign } from '../../redux/apis/campaignApi';
import { toast } from 'react-toastify';
import { clearError } from '../../redux/slices/campaignSlice';


const CampaignPlatforms = ({ open, setOpen, campaign }) => {
  const dispatch = useDispatch()
  const { error, isLoading } = useSelector(state => state.campaign)



  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      platforms: campaign?.platforms?.map(x => x.id)
    },
    validationSchema: Yup.object({
      platforms: Yup.array().nullable()
    }),
    onSubmit: async (values) => {
      dispatch(updateCampaign({ id: campaign?.id, data: values }))
      if (Object.keys(validation.errors).length === 0) {
        setOpen(false)
      }
    }
  });

  useEffect(() => {
    if (!error && !isLoading) {
      return
    }
    toast.error(error, { onclose: () => { dispatch(clearError()) } })
  }, [error, isLoading])

  

  return (
    <Modal
      open={open}
      title="Edit Campaign Platforms"
      onCancel={() => setOpen(false)}
      footer={[
        <Button key="back" onClick={() => setOpen(false)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => {
          if (Object.keys(validation.errors).length !== 0) {
            Object.keys(validation.errors).map((key) => {
              // toast.error(validation.errors[key]);
            });
            return;
          }
          validation.handleSubmit();
        }}>
          Submit
        </Button>
      ]}
    >
      {validation.values.platforms ?
        <PlatformsDropdown
          type="campaign"
          value={validation.values.platforms}
          onChange={e => validation.setFieldValue("platforms", e)}
          onBlur={validation.onBlur}
        />
        : null}
      <div className='flex flex-col'>
        {Object.keys(validation.errors).map((key) => (
          <p className='text-danger'>{validation.errors[key]}</p>
        ))}
      </div>
    </Modal>
  )
}

export default CampaignPlatforms