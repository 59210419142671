import React, { useEffect, useContext, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/apis/authApi.js';
import Loader from '../pages/loader.js';

function PagesLogin() {
    const dispatch = useDispatch();
    const { isLoading, error, user } = useSelector(state => state.auth);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLogged, setIsLogged] = useState(false)
    const context = useContext(AppSettings);

    useEffect(() => {
        context.setAppHeaderNone(true);
        context.setAppSidebarNone(true);
        context.setAppContentClass('p-0');

        return () => {
            context.setAppHeaderNone(false);
            context.setAppSidebarNone(false);
            context.setAppContentClass('');
        };
    }, [context]);

    useEffect(() => {
        if (user?.access_token) {
            localStorage.setItem("authUser", JSON.stringify(user.authUser));
            localStorage.setItem("access_token", user.access_token);
            localStorage.setItem("refresh_token", user.refresh_token);
            setIsLogged(true)
        }
    }, [user]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login({ email, password }));
    };

    if (isLogged) {
        return <Navigate to="/dashboard" replace />;
    }

    return (
        <div className="login">
            <Loader isLoading={isLoading} />
            <div className="login-content">
                <form onSubmit={handleSubmit}>
                    <h1 className="text-center">Sign In</h1>
                    <div className="text-inverse text-opacity-50 text-center mb-4">
                        For your protection, please verify your identity.
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Email Address <span className="text-danger">*</span></label>
                        <input
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control form-control-lg bg-white bg-opacity-5"
                            placeholder=""
                        />
                    </div>
                    <div className="mb-3">
                        <div className="d-flex">
                            <label className="form-label">Password <span className="text-danger">*</span></label>
                            <Link to="#/" className="ms-auto text-inverse text-decoration-none text-opacity-50">Forgot password?</Link>
                        </div>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control form-control-lg bg-white bg-opacity-5"
                            placeholder=""
                        />
                    </div>
                    <div className="mb-3">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="customCheck1" />
                            <label className="form-check-label" htmlFor="customCheck1">Remember me</label>
                        </div>
                    </div>
                    {error && <p className="h6 text-danger">{error}</p>}
                    <button type="submit" className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3">
                        {isLoading ? "Loading..." : "Sign In"}
                    </button>
                    <div className="text-center text-inverse text-opacity-50">
                        Don't have an account yet? <Link to="/pages/register">Sign up</Link>.
                    </div>
                </form>
            </div>
        </div>
    );
}

export default PagesLogin;
