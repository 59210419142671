import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardExpandToggler } from '../other/card/card';
import Chart from 'react-apexcharts';
import { perCustomerDetectedReportedRemoved } from '../../redux/apis/analyticsApi';
import { useDispatch, useSelector } from 'react-redux';
import ComponentLoader from '../other/ComponentLoader';

const PerCustomerStats = () => {
    const dispatch = useDispatch();
    const { isLoading, perCustomerDetectedReportedRemovedCampaigns } = useSelector(state => state.analytics);

    const [serverChartOptions, setServerChartOptions] = useState();
    const [serverChartData, setServerChartData] = useState([]);

    useEffect(() => {
        if (!perCustomerDetectedReportedRemovedCampaigns) {
            dispatch(perCustomerDetectedReportedRemoved());
        }
    }, []);

    function sanitizeColor(color) {
        // Check if the color is a valid 6-character hex or 3-character hex
        if (!/^#[0-9A-F]{6}$/i.test(color) && !/^#[0-9A-F]{3}$/i.test(color)) {
            return '#000000';  // Default to black if the color format is invalid
        }
        return color;
    }

    // Safeguard to get theme color with fallback
    function getThemeColor() {
        const appTheme = localStorage.getItem('appTheme'); // Get appTheme class from localStorage
        const themeColors = {
            'theme-pink': '#FF5CA7',
            'theme-red': '#E00000',
            'theme-warning': '#E9E9EF',
            'theme-yellow': '#FFFF5C',
            'theme-lime': '#80E15C',
            'theme-green': '#5DB762',
            'theme-teal': '#62CCA7',
            'theme-info': '#58CAFF',
            'theme-primary': '#589FFF',
            'theme-purple': '#EB5CFF',
            'theme-indigo': '#8B65FF',
            'theme-gray-200': '#9b59b6',
        };

        const themeColor = themeColors[appTheme] || '#28a745'; // Default to green if not found
        return themeColor;
    }

    // Safeguard to get axis text color with fallback
    function getAxisTextColor() {
        const appMode = localStorage.getItem('appMode'); // Get appMode class from localStorage
        const axisColor = appMode === 'dark' ? '#ffffff' : '#000000'; // White text for dark mode, black text for light mode
        return axisColor || '#000000'; // Fallback to black if color is invalid
    }

    // Generate chart data
    var generateServerChartData = (data) => {
        if (!data) {
            return [];
        }
        let series = [
            {
                name: 'Reported',
                data: Object.values(data).map((item) => item.reported),
            },
            {
                name: 'Removed',
                data: Object.values(data).map((item) => item.removed),
            },
        ];

        return series || [];
    };

    useEffect(() => {
        if (perCustomerDetectedReportedRemovedCampaigns) {
            setServerChartData(generateServerChartData(perCustomerDetectedReportedRemovedCampaigns));
            setServerChartOptions(getServerChartOptions());
        }
    }, [perCustomerDetectedReportedRemovedCampaigns]);

    // Safeguard for missing computedStyle values and fallback to defaults
    function getServerChartOptions() {
        // Get custom CSS properties or fallback values
        const borderColor = sanitizeColor(getComputedStyle(document.body).getPropertyValue('--bs-border-color').trim()) || '#ddd';
        const bodyColor = getComputedStyle(document.body).getPropertyValue('--bs-body-color').trim() || '#000';
        const themeFont = getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim() || 'Arial, sans-serif';
        const themeColor = getThemeColor();  // Fetch the theme color, can be dynamic
    
        // Log colors for debugging purposes
    
        return {
            chart: { 
                toolbar: { show: false }
            },
            plotOptions: { 
                bar: { 
                    horizontal: false, 
                    columnWidth: '55%', 
                    endingShape: 'rounded' 
                }
            },
            dataLabels: { enabled: false },
            grid: { 
                show: true, 
                borderColor: "#ddd" 
            },
            stroke: { show: false },
            colors: ['rgb(229, 231, 235)', themeColor],  // Array of colors for chart
            legend: { 
                fontFamily: themeFont, 
                labels: { colors: bodyColor } 
            },
            xaxis: {
                categories: Object.keys(perCustomerDetectedReportedRemovedCampaigns),  // Make sure this data exists
                labels: {
                    show: true,
                    style: { 
                        colors: getAxisTextColor(), 
                        fontSize: '12px', 
                        fontFamily: themeFont, 
                        fontWeight: 400 
                    },
                },
                axisBorder: { 
                    show: true, 
                    color: borderColor 
                },
                axisTicks: { 
                    show: true, 
                    color: borderColor 
                },
            },
            yaxis: {
                labels: {
                    style: { 
                        colors: getAxisTextColor(), 
                        fontSize: '12px', 
                        fontFamily: themeFont, 
                        fontWeight: 400 
                    },
                },
            },
            fill: { opacity: 0.65 },
            tooltip: { 
                y: { 
                    formatter: function (val) { 
                        return val + ' links'; 
                    } 
                }
            },
        };
    }

    return (
        <div>
            <Card className="mb-3">
                <CardBody className="relative p-0">
                    {isLoading?.perCustomerDetectedReportedRemoved ? <ComponentLoader /> : null}
                    <div className="p-4">
                        <div className="d-flex fw-bold small mb-3">
                            <span className="flex-grow-1">Reported | Removed Insights Per Customer</span>
                            <CardExpandToggler />
                        </div>

                        {serverChartData.length > 0 && (
                            <div className="ratio ratio-21x9 mb-3">
                                <Chart type="bar" height="100%" options={serverChartOptions} series={serverChartData} />
                            </div>
                        )}
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default PerCustomerStats;
