import { createSlice } from '@reduxjs/toolkit'
import { createVideo, createVideoBulk, createVideoDuration, createVideoTypes, listVideos, listVideosBulk, listVideosDuration, listVideosTypes, updateVideo, updateVideoBulk, updateVideoDuration, updateVideoTypes } from '../apis/videosApi';


const initialState = {
  videos: null,
  videosDuration: null,
  videosTypes: null,
  linkInsertion: null,
  bulkInsertion:null,
  isLoading: false,
  error: null,
  success: false
}

export const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    clearVideos: (state) => {
      state.videos = null
    },
    clearLinkInsertion: (state) => {
      state.linkInsertion = null
    },
    clearBulkInsertion: (state) => {
      state.bulkInsertion = null
    },
    clearLinkInsertionError: (state) => {
      state.error = null
    },
    clearError: (state) => {
      state.error = null
    },
    clearSuccess:(state)=>{
      state.success = false
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(listVideos.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listVideos.fulfilled, (state, action) => {
        state.isLoading = false;
        state.videos = action.payload
      })
      .addCase(listVideos.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(createVideo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createVideo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.linkInsertion = action.payload
        state.success = true
      })
      .addCase(createVideo.rejected, (state, action) => {
        state.isLoading = false;
        state.linkInsertion = action.payload
        state.error = action.error.message
      })
      //------------
      .addCase(updateVideo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateVideo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.linkInsertion = action.payload
      })
      .addCase(updateVideo.rejected, (state, action) => {
        state.isLoading = false;
        state.linkInsertion = action.payload
        state.error = action.error.message;
      })
      //------------
      .addCase(listVideosBulk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listVideosBulk.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(listVideosBulk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(createVideoBulk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createVideoBulk.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createVideoBulk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(updateVideoBulk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateVideoBulk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bulkInsertion = action.payload
      })
      .addCase(updateVideoBulk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(listVideosDuration.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listVideosDuration.fulfilled, (state, action) => {
        state.isLoading = false;
        state.videosDuration = action.payload
      })
      .addCase(listVideosDuration.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(createVideoDuration.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createVideoDuration.fulfilled, (state, action) => {
        state.isLoading = false;
        state.videosDuration = [...state.videosDuration, action.payload]
        state.success = true
      })
      .addCase(createVideoDuration.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(updateVideoDuration.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateVideoDuration.fulfilled, (state, action) => {
        state.isLoading = false;
        state.videosDuration = state.videosDuration.map(x => x.id === action.payload.id ? action.payload : x)
        state.success = true
      })
      .addCase(updateVideoDuration.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(listVideosTypes.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listVideosTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.videosTypes = action.payload
      })
      .addCase(listVideosTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(createVideoTypes.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createVideoTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.videosTypes = [...state.videosTypes, action.payload]
        state.success = true
      })
      .addCase(createVideoTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(updateVideoTypes.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateVideoTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.videosTypes = state.videosTypes.map(x => x.id === action.payload.id ? action.payload : x)
        state.success = true
      })
      .addCase(updateVideoTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
  },
})

// Action creators are generated for each case reducer function
export const { clearVideos, clearLinkInsertion, clearLinkInsertionError, clearBulkInsertion, clearError, clearSuccess } = videoSlice.actions

export default videoSlice.reducer