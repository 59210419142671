import React from 'react'
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Input, Modal, Select } from 'antd';
import { createUser, UpdateUser } from '../../redux/apis/authApi';
import GroupsDropdown from '../GroupsDropdown'
import PermissionsDropdown from '../PermissionsDropdown';
import { toast } from 'react-toastify';
const EditCreateUser = ({ state, open, setOpen, user }) => {
    const dispatch = useDispatch()

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            email: user?.email || "",
            first_name: user?.first_name || "",
            last_name: user?.last_name || "",
            is_superuser: user?.is_superuser || false,
            is_active: user?.is_active === false ? false : true,
            is_staff: user?.is_staff === false ? false : true,
            password: "",
            repeat_password: "",
            groups: user?.groups || [],
            user_permissions: user?.user_permissions || []
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email address").required("Please Enter Email"),
            first_name: Yup.string().required("Please Enter First Name"),
            last_name: Yup.string().required("Please Enter Last Name"),
            is_active: Yup.bool().required("Please Enter user status"),
            password: user ? Yup.string().nullable() : Yup.string().required("Please Enter Password"),
            repeat_password: user ? Yup.string().nullable() : Yup.string().required("Please Enter Your Repeat Password").oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),
        onSubmit: async (values) => {

            Object.entries(values).forEach(([key, value]) => {
                if (key === 'password' || key === 'repeat_password') {
                    if (value === "") {
                        delete values[key]
                    }
                }
            })
            if (state === "edit") {
                dispatch(UpdateUser({ data: values, id: user?.id }))
            }
            if (state === "create") {
                dispatch(createUser(values))
            }
            if (Object.keys(validation.errors).length === 0) {
                setOpen(false)
            }
        },
    })

    return (
        <Modal
            className='customModal'
            open={open}
            title={state === "edit" ? "Edit User" : "Add New User"}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={() => {
                    if (Object.keys(validation.errors).length !== 0) {
                        Object.keys(validation.errors).map((key) => {
                            toast.error(validation.errors[key]);
                        });
                        return;
                    }
                    validation.handleSubmit();
                }}>
                    Submit
                </Button>
            ]}
        >
            <div className=" mb-3">
                <label className="" >Email</label>
                <Input
                    required
                    type="email"
                    name='email'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email}
                />
                {validation.touched.email &&
                    validation.errors.email ? (
                    <p className='text-danger'>
                        {validation.errors.email}
                    </p>
                ) : null}
            </div>
            <div className=" mb-3">
                <label className="" >First Name</label>
                <Input
                    required
                    type="text"
                    name='first_name'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.first_name}
                />
                {validation.touched.first_name &&
                    validation.errors.first_name ? (
                    <p className='text-danger'>
                        {validation.errors.first_name}
                    </p>
                ) : null}
            </div>
            <div className=" mb-3">
                <label className="" >Last Name</label>
                <Input
                    required
                    type="text"
                    autoComplete=''
                    name='last_name'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.last_name}
                />
                {validation.touched.last_name &&
                    validation.errors.last_name ? (
                    <p className='text-danger'>
                        {validation.errors.last_name}
                    </p>
                ) : null}
            </div>
            <div className=" mb-3">
                <label className="" >Password</label>
                <Input
                    required
                    autoComplete="new-password"
                    type="password"
                    name='password'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password}
                />
                {validation.touched.password &&
                    validation.errors.password ? (
                    <p className='text-danger'>
                        {validation.errors.password}
                    </p>
                ) : null}
            </div>
            <div className=" mb-3">
                <label className="" >Repeat Password</label>
                <Input
                    required
                    type="password"
                    name='repeat_password'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.repeat_password}
                />
                {validation.touched.repeat_password &&
                    validation.errors.repeat_password ? (
                    <p className='text-danger'>
                        {validation.errors.repeat_password}
                    </p>
                ) : null}
            </div>
            <div className=" mb-3">
                <label className="" >Is Admin</label>
                <Select
                    style={{
                        width: '100%',
                    }}
                    name="is_superuser"
                    placeholder="Please select"
                    onBlur={validation.handleBlur}
                    value={validation.values.is_superuser}
                    onChange={(e) => validation.setFieldValue("is_superuser", e)}
                    options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                    ]}
                />
                {validation.touched.is_superuser &&
                    validation.errors.is_superuser ? (
                    <p className='text-danger'>
                        {validation.errors.is_superuser}
                    </p>
                ) : null}
            </div>
            <div className=" mb-3">
                <label className="" >Is Staff</label>
                <Select
                    style={{
                        width: '100%',
                    }}
                    name="is_staff"
                    placeholder="Please select"
                    onBlur={validation.handleBlur}
                    value={validation.values.is_staff}
                    onChange={(e) => validation.setFieldValue("is_staff", e)}
                    options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                    ]}
                />
                {validation.touched.is_staff &&
                    validation.errors.is_staff ? (
                    <p className='text-danger'>
                        {validation.errors.is_staff}
                    </p>
                ) : null}
            </div>
            <div>
                <GroupsDropdown
                    onBlur={validation.handleBlur}
                    onChange={validation.setFieldValue}
                    value={validation.values.groups}
                />
                {validation.touched.groups && validation.errors.groups ? (
                    <p className='text-danger'>{validation.errors.groups}</p>
                ) : null}
            </div>
            <div>
                <PermissionsDropdown
                    onBlur={validation.handleBlur}
                    onChange={validation.setFieldValue}
                    value={validation.values.user_permissions}
                />
                {validation.touched.user_permissions && validation.errors.user_permissions ? (
                    <p className='text-danger'>{validation.errors.user_permissions}</p>
                ) : null}
            </div>

        </Modal>
    )
}

export default EditCreateUser