import React from 'react'
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Input, Modal, Select } from 'antd';
import { createPlatform, updatePlatform } from '../../redux/apis/platformApi';
import { toast } from 'react-toastify';

const EditCreatePlatform = ({ state, open, setOpen, platform }) => {
    const dispatch = useDispatch()


    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            platform_name: platform?.platform_name || "",
            description: platform?.description || "",
            reporting_abuse_to: platform?.reporting_abuse_to || "",
            domain_abuse_email: platform?.domain_abuse_email || "",
            domain_abuse_form: platform?.domain_abuse_form || "",
            platform_status: platform?.platform_status === true ? true : platform?.platform_status === false ? false : "",
        },
        validationSchema: Yup.object({
            platform_name: Yup.string().required("Please Enter Service Name"),
            description: Yup.string().nullable(),
            reporting_abuse_to: Yup.string().required("Please Enter Reporting Abuse To"),
            domain_abuse_email: Yup.string()
                .email("Please Enter a valid email address.")
                .required("Please Enter a valid email address in domain abuse email."),
            domain_abuse_form: Yup.string()
                .url("Enter a valid URL.")
                .required("Enter a valid URL in domain abuse form."),
        }),
        onSubmit: async (values) => {

            if (state === "edit") {
                dispatch(updatePlatform({ data: values, id: platform?.id }))
            }
            if (state === "create") {
                dispatch(createPlatform(values))
            }
            if (Object.keys(validation.errors).length === 0) {
                setOpen(false)
            }
        },
    })

    return (
        <Modal
            className='customModal'
            open={open}
            title={state === "edit" ? "Edit Platform" : "Add New Platform"}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={() => {
                    if (Object.keys(validation.errors).length !== 0) {
                        Object.keys(validation.errors).map((key) => {
                            toast.error(validation.errors[key]);
                        });
                        return;
                    }
                    validation.handleSubmit();
                }}>
                    Submit
                </Button>
            ]}
        >
            <div className=" mb-3">
                <label className="" >Platform Name</label>
                <Input
                    required
                    type="text"
                    name='platform_name'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.platform_name}
                />
                {validation.touched.platform_name &&
                    validation.errors.platform_name ? (
                    <p className='text-danger'>
                        {validation.errors.platform_name}
                    </p>
                ) : null}
            </div>
            <div className=" mb-3">
                <label className="" >Reporting Abuse To</label>
                <Input
                    required
                    type="text"
                    name='reporting_abuse_to'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.reporting_abuse_to}
                />
                {validation.touched.reporting_abuse_to &&
                    validation.errors.reporting_abuse_to ? (
                    <p className='text-danger'>
                        {validation.errors.reporting_abuse_to}
                    </p>
                ) : null}
            </div>
            <div className=" mb-3">
                <label className="" >Domain Abuse Email</label>
                <Input
                    required
                    type="text"
                    name='domain_abuse_email'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.domain_abuse_email}
                />
                {validation.touched.domain_abuse_email &&
                    validation.errors.domain_abuse_email ? (
                    <p className='text-danger'>
                        {validation.errors.domain_abuse_email}
                    </p>
                ) : null}
            </div>
            <div className=" mb-3">
                <label className="" >Domain Abuse Form</label>
                <Input
                    required
                    type="text"
                    name='domain_abuse_form'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.domain_abuse_form}
                />
                {validation.touched.domain_abuse_form &&
                    validation.errors.domain_abuse_form ? (
                    <p className='text-danger'>
                        {validation.errors.domain_abuse_form}
                    </p>
                ) : null}
            </div>
            <div className=" mb-3">
                <label className="" >Platform Description</label>
                <Input
                    required
                    type="text"
                    name='description'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.description}
                />
                {validation.touched.description && validation.errors.description ? (
                    <p className='text-danger'>
                        {validation.errors.description}
                    </p>
                ) : null}
            </div>
            <div className=" mb-3">
                <label className="" >Platform Status</label>
                <Select
                    style={{
                        width: '100%',
                    }}
                    placeholder="Please select"
                    onBlur={validation.handleBlur}
                    value={validation.values.platform_status}
                    onChange={(e) => validation.setFieldValue("platform_status", e)}
                    options={[
                        { label: "Active", value: true },
                        { label: "InActive", value: false },
                    ]}
                />
                {validation.touched.platform_status && validation.errors.platform_status ? (
                    <p className='text-danger'>
                        {validation.errors.platform_status}
                    </p>
                ) : null}
            </div>

        </Modal>
    )
}

export default EditCreatePlatform