import React, { useContext, useEffect } from 'react';
import { AppSettings } from '../../config/app-settings';
import AnalyticsBox from '../../components/Dashboard/AnalyticsBox';
import PerCustomerStats from '../../components/Dashboard/PerCustomerStats';
import PerServiceStats from '../../components/Dashboard/PerServiceStats';
import TopCampaigns from '../../components/Dashboard/TopCampaigns';
import ServicesData from '../../components/Dashboard/ServicesData';
import MonthlyDetectedRemoved from '../../components/Dashboard/MonthlyDetectedRemoved';
import TopRemovals from '../../components/Dashboard/TopRemovals';

const Dashboard = React.memo(() => {
    const context = useContext(AppSettings);

    useEffect(() => {
        context.setAppHeaderNone(false);
        context.setAppSidebarNone(false);
        context.setAppContentClass('');
        context.setAppThemePanal(true);

        return () => {
            context.setAppHeaderNone(true);
            context.setAppSidebarNone(true);
            context.setAppContentClass('p-0');
            context.setAppThemePanal(false);
        };
    }, [context]); // Add context as a dependency

    return (
        <div>
            <div className="row">
                <AnalyticsBox />
                <PerCustomerStats />
                <PerServiceStats />
                <TopCampaigns />
                <ServicesData />
                <div className='flex justify-between wrapContainer gap-4' style={{flexWrap:"wrap"}}>
                    <MonthlyDetectedRemoved />
                    <TopRemovals />
                </div>
            </div>
        </div>
    );
});

export default Dashboard;
