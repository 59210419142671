import axiosInstance from '../../helpers/conf'
import { createAsyncThunk } from '@reduxjs/toolkit';


export const listcountry = createAsyncThunk(
  'country/listcountry',
  async (params) => {
    const response = await axiosInstance.get(`utils/countries/`, {params:{...params}});
    return response.results;
  }
);
export const createCountry = createAsyncThunk(
  'country/createCountry',
  async (data) => {
    const response = await axiosInstance.post(`utils/countries/`, data);
    return response.results;
  }
);
export const updateCountry = createAsyncThunk(
  'country/updateCountry',
  async (data, id) => {
    const response = await axiosInstance.patch(`utils/countries/${id}/`, data);
    return response.results;
  }
);

export const listLanguage = createAsyncThunk(
  'language/listLanguage',
  async (params) => {
    const response = await axiosInstance.get(`utils/languages/`, params);
    return response.results;
  }
);

export const createLanguage = createAsyncThunk(
  'language/createLanguage',
  async (data) => {
    const response = await axiosInstance.post(`utils/languages/`, data);
    return response.results;
  }
);

export const updateLanguage = createAsyncThunk(
  'language/updateLanguage',
  async (data, id) => {
    const response = await axiosInstance.patch(`utils/languages/${id}/`, data);
    return response.results;
  }
);