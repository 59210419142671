import React from 'react'
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Input, Modal, Select } from 'antd';
import { createVideoDuration, updateVideoDuration } from '../../redux/apis/videosApi';
import { toast } from 'react-toastify';

const EditCreateVideoDuration = ({ state, open, setOpen, videoDuration }) => {
    const dispatch = useDispatch()


    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            duration: videoDuration?.duration || "",
        },

        validationSchema: Yup.object({
            duration: Yup.string().required("Please Enter Duration"),
        }),
        onSubmit: async (values) => {

            if (state === "edit") {
                dispatch(updateVideoDuration({ data: values, id: videoDuration?.id }))
            }
            if (state === "create") {
                dispatch(createVideoDuration(values))
            }
            if (Object.keys(validation.errors).length === 0) {
                setOpen(false)
            }
        },
    });

    return (
        <Modal
            className='customModal'
            open={open}
            title={state === "edit" ? "Edit Video Duration" : "Add New Video Duration"}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={() => {
                    if (Object.keys(validation.errors).length !== 0) {
                        Object.keys(validation.errors).map((key) => {
                            toast.error(validation.errors[key]);
                        });
                        return;
                    }
                    validation.handleSubmit();
                }}>
                    Submit
                </Button>
            ]}
        >
            <div className=" mb-3">
                <label className="" >Video Duration</label>
                <Input
                    required
                    type="text"
                    name='duration'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.duration}
                />
            </div>
            
            <div className='flex flex-col'>
                {Object.keys(validation.errors).map((key) => (
                    <p className='text-danger'>{validation.errors[key]}</p>
                ))}
            </div>
        </Modal>
    )
}

export default EditCreateVideoDuration