
import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Space } from 'antd';
import { SearchOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';

const ReusableTable = ({ data, columns }) => {
    const [filteredData, setFilteredData] = useState(data);
    const [searchText, setSearchText] = useState('');
    const [filter, setFilter] = useState(null); // State for filter

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    useEffect(() => {
        // Update filtered data based on the current filter
        const applyFilters = () => {
            let newData = data;
            if (filter !== null) {
                newData = newData.filter(item => item.campaign_status === filter);
            }
            setFilteredData(newData);
        };
        applyFilters();
    }, [filter, data]);

    const handleSearch = (value, key) => {
        const filtered = data.filter(item => {
            const keys = key.split('.');
            const itemValue = keys.reduce((o, k) => (o || {})[k], item);
            return itemValue ? itemValue.toString().toLowerCase().includes(value.toLowerCase()) : false;
        });
        setFilteredData(filtered);
    };

    const handleReset = () => {
        setSearchText('');
        setFilter(null); // Reset filter
        setFilteredData(data);
    };

    const processedColumns = columns.map(col => ({
        ...col,
        filterDropdown: col.search ? ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${col.title}`}
                    value={selectedKeys[0] || searchText}
                    onChange={e => {
                        const value = e.target.value;
                        setSelectedKeys(value ? [value] : []);
                        setSearchText(value);
                    }}
                    onPressEnter={() => {
                        confirm();
                        handleSearch(searchText, col.key);
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => {
                            confirm();
                            handleSearch(searchText, col.key);
                        }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => {
                        handleReset();
                        clearFilters();
                    }}>Reset</Button>
                </Space>
            </div>
        ) : null,
        onFilter: (value, record) => {
            if (col.filters) {
                return record[col.dataIndex] === value; // Filter based on defined filters
            }
            const keys = col.key.split('.');
            const itemValue = keys.reduce((o, k) => (o || {})[k], record);
            return itemValue ? itemValue.toString().toLowerCase().includes(value.toLowerCase()) : false;
        },
        sorter: col.sorter ? (a, b) => {
            const aValue = col.key.split('.').reduce((o, k) => (o || {})[k], a);
            const bValue = col.key.split('.').reduce((o, k) => (o || {})[k], b);
            if (typeof aValue === 'number' && typeof bValue === 'number') {
                return aValue - bValue;
            }
            return (aValue || '').toString().localeCompare((bValue || '').toString());
        } : null,
        sortIcon: ({ sortOrder }) => (
            <>
                {sortOrder === 'ascend' ? <SortAscendingOutlined className={"sortIcon"} /> : <SortDescendingOutlined className={"sortIcon"} />}
            </>
        ),
        filterIcon: filtered => (
            <SearchOutlined className='searchBtn' />
        ),
        render: col.render || ((text) => text),
    }));

    return (
        <Table
            className='tableContainer'
            dataSource={filteredData}
            columns={processedColumns}
            rowKey="id"
        />
    );
};

export default ReusableTable;