// app-route.js
import React from 'react';
import App from './../app.jsx';
import PagesError from './../pages/pages/error.js';
import PagesLogin from '../pages/auth/login.js';
import PagesRegister from '../pages/auth/register.js';
import Profile from '../pages/profile/Profile.js';
import Settings from '../pages/settings/Settings.js';
import Dashboard from '../pages/dashboard/dashboard.js';
import { Navigate } from 'react-router-dom';
import Customers from '../pages/customers/Customers.js';
import CampaignsInfo from '../pages/campaigns/CampaignsInfo.js';
import CampaignsLoa from '../pages/campaigns/CampaignsLoa.js';
import Platforms from '../pages/platforms/Platforms.js';
import VideoDurations from '../pages/video/VideoDurations.js';
import VideoTypes from '../pages/video/VideoTypes.js';
import LinkInsertionFacebook from '../pages/linkInsertion/LinkInsertionFacebook.js';
import LinkInsertionInstagram from '../pages/linkInsertion/LinkInsertionInstagram.js';
import LinkInsertionTwitter from '../pages/linkInsertion/LinkInsertionTwitter.js';
import LinkInsertionYoutube from '../pages/linkInsertion/LinkInsertionYoutube.js';
import LinkInsertionTiktok from '../pages/linkInsertion/LinkInsertionTiktok.js';
import LinkInsertionTwitch from '../pages/linkInsertion/LinkInsertionTwitch.js';
import LinkInsertionSnapchat from '../pages/linkInsertion/LinkInsertionSnapchat.js';
import SocialMediaLinkMarking from '../pages/LinkMarking/SocialMediaLinkMarking.js';
import ChangeLinksCampaign from '../pages/UpdateLinks/ChangeLinksCampaign.js';
import GetLinksDetails from '../pages/inquiries/GetLinksDetails.js';
import ContentIDLinkInsertionFacebook from '../pages/contentIdLinksInsertion/ContentIDLinkInsertionFacebook.js';
import ContentIDLinkInsertionInstagram from '../pages/contentIdLinksInsertion/ContentIDLinkInsertionInstagram.js';
import ContentIDLinkInsertionYoutube from '../pages/contentIdLinksInsertion/ContentIDLinkInsertionYoutube.js';
import WhitelistChannels from '../pages/whitelistChannels/WhitelistChannels.js';
import Users from '../pages/auth/Users.js';
import Permissions from '../pages/auth/Permissions.js';
import Groups from '../pages/auth/Groups.js';
import OpsPerformance from '../pages/OpsPerformance/OpsPerformance.js';
import GenerateReports from '../pages/GenerateReports/GenerateReports.js';
import BackgroundTasks from '../pages/backgroundTasks/BackgroundTasks.js';
import CampaignDetails from '../pages/campaigns/CampaignDetails.js';
import CampaignsAnalytics from '../pages/campaigns/CampaignsAnalytics.js';






export const AppRoute = [
  {
    path: '*',
    element: <App />,
    children: [
      { path: '', element: <Navigate to={"/dashboard"} /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'campaigns-analytics', element: <CampaignsAnalytics /> },
      { path: 'campaigns-analytics/:campaign_id', element: <CampaignDetails /> },
      { path: 'customers', element: <Customers /> },
      {
        path: 'campaigns/*',
        children: [
          { path: 'campaigns-info', element: <CampaignsInfo /> },
          { path: 'campaigns-loa', element: <CampaignsLoa /> },
        ]
      },
      { path: 'platforms', element: <Platforms /> },
      { path: 'video-durations', element: <VideoDurations /> },
      { path: 'video-types', element: <VideoTypes /> },
      {
        path: 'link-insertion/*',
        children: [

          { path: 'facebook', element: <LinkInsertionFacebook /> },
          { path: 'instagram', element: <LinkInsertionInstagram /> },
          { path: 'twitter', element: <LinkInsertionTwitter /> },
          { path: 'youtube', element: <LinkInsertionYoutube /> },
          { path: 'tiktok', element: <LinkInsertionTiktok /> },
          { path: 'twitch', element: <LinkInsertionTwitch /> },
          { path: 'snapchat', element: <LinkInsertionSnapchat /> },
        ]
      },
      {
        path: 'link-marking/*', children: [
          { path: 'social-media-marking', element: <SocialMediaLinkMarking /> }
        ]
      },
      {
        path: "update-links/*", children: [
          { path: 'change-links-campaign', element: <ChangeLinksCampaign /> },

        ]
      },
      {
        path: "inquiries/*", children: [
          { path: 'get-links-details', element: <GetLinksDetails /> },
        ]
      },
      {
        path: "contentid-links-insertion/*",
        children: [
          { path: 'facebook', element: <ContentIDLinkInsertionFacebook /> },
          { path: 'instagram', element: <ContentIDLinkInsertionInstagram /> },
          { path: 'youtube', element: <ContentIDLinkInsertionYoutube /> },
        ]
      },
      { path: 'whitelist-channels', element: <WhitelistChannels /> },
      {
        path:"auth/*",
        children:[
          { path: 'users', element: <Users /> },
          { path: 'permissions', element: <Permissions /> },
          { path: 'groups', element: <Groups /> },
        ]
      },
      { path: 'ops-performance', element: <OpsPerformance /> },
      { path: 'generate-reports', element: <GenerateReports /> },
      { path: 'background-tasks', element: <BackgroundTasks /> },
      { path: 'login', element: <PagesLogin /> },
      { path: 'register', element: <PagesRegister /> },
      { path: 'profile', element: <Profile />, protected: true },
      { path: 'settings', element: <Settings />, protected: true },
      { path: '*', element: <PagesError /> },
    ]
  }
];


