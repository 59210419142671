import axiosInstance from '../../helpers/conf'
import { createAsyncThunk } from '@reduxjs/toolkit';


export const listVideos = createAsyncThunk(
  'videos/listVideos',
  async (params) => {
    const response = await axiosInstance.get(`videos/`, {params:{...params}});
    return response.results;
  }
);
export const createVideo = createAsyncThunk(
  'videos/createVideo',
  async (data,{rejectWithValue}) => {
    try {
      const response = await axiosInstance.post(`videos/`, data);
      return response;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);
export const updateVideo = createAsyncThunk(
  'videos/updateVideo',
  async ({data, id}) => {
    const response = await axiosInstance.patch(`videos/${id}/`, data);
    return response;
  }
);
//---------------

export const listVideosBulk = createAsyncThunk(
  'videos/listVideosBulk',
  async (params) => {
    const response = await axiosInstance.get(`videos/bulk/`, params);
    return response.results;
  }
);
export const createVideoBulk = createAsyncThunk(
  'videos/createVideoBulk',
  async (data) => {
    const response = await axiosInstance.post(`videos/bulk/`, data);
    return response;
  }
);
export const updateVideoBulk = createAsyncThunk(
  'videos/updateVideoBulk',
  async (data) => {
    const response = await axiosInstance.patch(`videos/bulk/`, data);
    return response;
  }
);

//----------------

export const listVideosDuration = createAsyncThunk(
  'videos/listVideosDuration',
  async (params) => {
    const response = await axiosInstance.get(`videos/durations/`, {params:{...params}});
    return response.results;
  }
);
export const createVideoDuration = createAsyncThunk(
  'videos/createVideoDuration',
  async (data) => {
    const response = await axiosInstance.post(`videos/durations/`, data);
    return response;
  }
);
export const updateVideoDuration = createAsyncThunk(
  'videos/updateVideoDuration',
  async ({data, id}) => {
    const response = await axiosInstance.patch(`videos/durations/${id}/`, data);
    return response;
  }
);

//---------------------

export const listVideosTypes = createAsyncThunk(
  'videos/listVideosTypes',
  async (params) => {
    const response = await axiosInstance.get(`videos/types/`, {params:{...params}});
    return response.results;
  }
);
export const createVideoTypes = createAsyncThunk(
  'videos/createVideoTypes',
  async (data) => {
    const response = await axiosInstance.post(`videos/types/`, data);
    return response;
  }
);
export const updateVideoTypes = createAsyncThunk(
  'videos/updateVideoTypes',
  async ({data, id}) => {
    const response = await axiosInstance.patch(`videos/types/${id}/`, data);
    return response;
  }
);