import React, { useContext, useEffect } from 'react';
import { AppSettings } from '../../config/app-settings';
import { Card, CardBody, CardHeader } from '../../components/other/card/card'
import { useDispatch, useSelector } from 'react-redux';
import { backgroundTasks } from '../../redux/apis/analyticsApi';
import Loader from '../pages/loader'
import TaskDefinitionCard from '../../components/backgroundTask/TaskDefinitionCard';
import { toast } from 'react-toastify';
import {clearError} from '../../redux/slices/analyticsSlice'

function BackgroundTasks() {
	const dispatch = useDispatch()
	const { backgroundTasksData, isLoading, error } = useSelector(state => state.analytics)

	useEffect(() => {
			dispatch(backgroundTasks({
				expand: ['task', 'user'].join(','),
				fields: ['user.email', 'task.id', 'task.task_name', 'task.status', 'task.result', 'task.date_created', 'task.date_done'].join(','),
			}))
	}, [])

	const TASK_TYPES = ['QUEUED', 'STARTED', 'SUCCESS', 'FAILURE']

	const context = useContext(AppSettings);
	useEffect(() => {
		context.setAppHeaderNone(false);
		context.setAppSidebarNone(false);
		context.setAppContentClass('');
		context.setAppThemePanal(true)

		return function cleanUp() {
			context.setAppHeaderNone(true);
			context.setAppSidebarNone(true);
			context.setAppContentClass('p-0');
			context.setAppThemePanal(false)
		};
	}, []);

	
	useEffect(()=>{
        if ( !error && !isLoading) {
            return
        }
        toast.error(error,{ onclose: ()=>{dispatch(clearError())}})
    },[error, isLoading])

	return (
		<div className='flex gap-4 w-full'>
			<Loader isLoading={isLoading.backgroundTasks} />
			{TASK_TYPES.map((type, i) => {
				return (
					<div className='grow max-w-[25%]' key={i}>
						<Card>
							<CardHeader>
								{type}
							</CardHeader>
							<CardBody>
								{
									backgroundTasksData?.filter(
										x => x.task.status === type
									).map(task=>(
										<TaskDefinitionCard task={task}/>
									))
								}
							</CardBody>
						</Card>
					</div>

				)
			})}
		</div>
	)
}

export default BackgroundTasks;