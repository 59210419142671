import axiosInstance from '../../helpers/conf'
import { createAsyncThunk } from '@reduxjs/toolkit';

export const listUsers = createAsyncThunk(
  'customer/list',
  async (params) => {
    const response = await axiosInstance.get(`customers/`, { params: { ...params } });
    return response.results;
  }
);
export const listCustomerPlatforms = createAsyncThunk(
  'customer/listPlatforms',
  async (params) => {
    const response = await axiosInstance.get(`campaigns/platforms/bulk/`, {params:{...params}});
    return response;
  }
);
export const bulkCreatePlatforms = createAsyncThunk(
  'customer/bulkCreatePlatforms',
  async (data) => {
    const response = await axiosInstance.post(`campaigns/platforms/bulk/`, data);
    return response.results;
  }
);
export const createCustomer = createAsyncThunk(
  'customer/createCustomer',
  async (data) => {
    const response = await axiosInstance.post(`customers/?expand=user,last_updated_by,country`, data);
    return response;
  }
);

export const updateCustomer = createAsyncThunk(
  'customer/updateCustomer',
  async ({ data, id }) => {
    const response = await axiosInstance.patch(`customers/${id}/?expand=user,last_updated_by,country`, data);
    return response;
  }
);
