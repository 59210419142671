import { Button, Modal } from 'antd';
import React from 'react'
import { useDispatch } from 'react-redux';
import { activateUser, deleteUser } from '../../redux/apis/authApi';

const DeactivateUser = ({ open, setOpen, user }) => {
    const dispatch = useDispatch()
    
    return (
        <Modal
            className='customModal'
            open={open}
            title={"Deactivate User"}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={() => {
                    if (user?.is_active === true) {
                        dispatch(deleteUser(user?.id))
                        setOpen(false)
                    } else {
                        dispatch(activateUser(user?.id))
                        setOpen(false)
                    }
                }}>
                    Submit
                </Button>
            ]}
        >
            <p>
                Are you sure you want to {user?.is_active === true ? "Deactivate" : "Activate"} {user?.email}?
            </p>
        </Modal>
    )
}

export default DeactivateUser