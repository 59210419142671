import * as XLSX from 'xlsx';

function generateExcel(data, filename = "data") {
    // Check if data is valid (e.g., it must be an array of objects)
    if (!Array.isArray(data) || data.length === 0) {
      throw new Error("Invalid or empty data provided.");
    }

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert the data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Set the style for each cell to prevent text wrapping
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    
    // Iterate over all cells and apply the style
    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cell = worksheet[XLSX.utils.encode_cell({ r: row, c: col })];
        if (cell) {
          // Apply style to prevent text wrapping
          if (!cell.s) cell.s = {};  // Initialize the style object if it doesn't exist
          cell.s.alignment = cell.s.alignment || {}; // Initialize alignment object if not defined
          cell.s.alignment.wrapText = false;
        }
      }
    }

    // Set column widths based on the maximum length of the data in each column
    const columnWidths = [];
    for (let col = range.s.c; col <= range.e.c; col++) {
      let maxLength = 0;
      for (let row = range.s.r; row <= range.e.r; row++) {
        const cell = worksheet[XLSX.utils.encode_cell({ r: row, c: col })];
        if (cell && cell.v) {
          maxLength = Math.max(maxLength, cell.v.toString().length);
        }
      }
      columnWidths.push({ wpx: maxLength * 10 }); // Adjust multiplier for width as needed
    }
    
    worksheet['!cols'] = columnWidths;

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate the XLSX file and trigger the download
    XLSX.writeFile(workbook, `${filename}.xlsx`);

  
}

export default generateExcel;
