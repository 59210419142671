import { createSlice } from '@reduxjs/toolkit'
import { createWhitelist, listWhiteList, updateWhitelist } from '../apis/whitelistApi';


const initialState = {
  whitelist: null,
  createUpdateRes:null,
  isLoading: false,
  error: null,
  success: false
}

export const whitelistSlice = createSlice({
  name: 'whitelist',
  initialState,
  reducers: {
    clearWhitelist:(state)=>{
      state.whitelist = null
    },
    clearCreateUpdateRes:(state)=>{
      state.createUpdateRes = null
    },
    clearError:(state)=>{
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listWhiteList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listWhiteList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.whitelist = action.payload
      })
      .addCase(listWhiteList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(createWhitelist.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createWhitelist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.createUpdateRes = action.payload
      })
      .addCase(createWhitelist.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(updateWhitelist.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateWhitelist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.createUpdateRes = action.payload
      })
      .addCase(updateWhitelist.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
    //------------
  },
})

// Action creators are generated for each case reducer function
export const { clearWhitelist, clearCreateUpdateRes, clearError} = whitelistSlice.actions

export default whitelistSlice.reducer