import React, { useContext, useEffect, useState } from 'react';
import { AppSettings } from '../../config/app-settings';
import Loader from '../pages/loader';
import ReusableTable from '../../components/other/ReuseableTable';
import { useDispatch, useSelector } from 'react-redux';
import { listPermissions } from '../../redux/apis/authApi';
import EditCreatePermissions from '../../components/users/EditCreatePermissions';
import { can } from '../../helpers/auth';
import NoPermission from '../pages/noPermission';
import { clearError } from '../../redux/slices/permissionsSlice';
import { toast } from 'react-toastify';
import { clearSuccess } from '../../redux/slices/authSlice';

function Permissions() {
	const dispatch = useDispatch()
	const { isLoading, permissions, error, success } = useSelector(state => state.permissions)
	const [edit, setEdit] = useState(false)
	const [create, setCreate] = useState(false)
	const [holder, setHolder] = useState()

	useEffect(() => {
		if (!permissions) {
			dispatch(listPermissions())
		}
	}, [])

	let columns = [
		{ title: "ID", dataIndex: "id", key: "id", sorter: true },
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			sorter: true,
			search: true,
		},

		{
			title: 'Code',
			dataIndex: 'codename',
			key: 'codename',
			sorter: true,
			search: true,
		},

	];

	if (can("change_permission")) {
		columns.push({
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<div className='flex gap-2'>
					<button className='btn btn-outline-theme' onClick={() => handleEdit(record)}>Edit</button>
				</div>
			),
		})
	}

	const handleEdit = (record) => {
		setHolder(record)
		setEdit(true)
	};

	const context = useContext(AppSettings);
	useEffect(() => {
		context.setAppHeaderNone(false);
		context.setAppSidebarNone(false);
		context.setAppContentClass('');
		context.setAppThemePanal(true)

		return function cleanUp() {
			context.setAppHeaderNone(true);
			context.setAppSidebarNone(true);
			context.setAppContentClass('p-0');
			context.setAppThemePanal(false)
		};
	}, []);

	useEffect(() => {
		if (!error && !isLoading) {
			return
		}
		toast.error(error, { onclose: () => { dispatch(clearError()) } })
	}, [error, isLoading])


	useEffect(() => {
		if (success) {
			toast.success("Action successfuly", { onclose: () => { dispatch(clearSuccess()) } })
		}
	}, [success])

	console.log(isLoading, success, permissions)

	if (!can("view_permission")) {
		return <NoPermission />
	}

	return (
		<div className='w-full flex flex-col'>
			<Loader isLoading={isLoading} />
			{edit ? <EditCreatePermissions state={"edit"} open={edit} setOpen={setEdit} permission={holder} /> : null}
			{create ? <EditCreatePermissions state={"create"} open={create} setOpen={setCreate} permission={null} /> : null}
			<button className='btn btn-outline-theme ml-auto' onClick={() => setCreate(true)}>Add New Permissions</button>
			<br />
			{permissions ?
				<ReusableTable
					data={permissions}
					columns={columns}
				/>
				: null
			}
		</div>
	)
}

export default Permissions;