import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const AuthMiddleware = ({ children }) => {
  const location = useLocation();
  const isAuthenticated = !!localStorage.getItem("authUser");

  // Redirect to login if not authenticated and trying to access a protected route
  if (!isAuthenticated && location.pathname !== "/login") {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <>{children}</>;
};

export default AuthMiddleware;
