import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select } from 'antd'
import { listPermissions } from '../redux/apis/authApi'
import { clearError } from '../redux/slices/permissionsSlice'
import { toast } from 'react-toastify'

const PermissionsDropdown = ({ onChange, onBlur, value, multi = true, name = "user_permissions" }) => {
    const dispatch = useDispatch()
    const { permissions, isLoading, error } = useSelector(state => state.permissions)
    let options = []
    useEffect(() => {
        if (!permissions) {

            dispatch(listPermissions())
        }
    }, [])
    if (permissions) {
        options = permissions.map((item) => {
            return {
                label: item.name,
                value: item.id
            }
        })
    }


    useEffect(() => {
        if (!error && !isLoading) {
            return
        }
        toast.error(error, { onclose: () => { dispatch(clearError()) } })
    }, [error, isLoading])
    return (
        <div className=" mb-3">
            <label className="" >permissions</label>
            <br />
            <Select
                mode={multi ? "multiple" : ""}
                allowClear
                style={{
                    width: '100%',
                }}
                loading={isLoading}
                placeholder="Please select"
                onBlur={onBlur}
                value={value}
                onChange={(e) => onChange(name, e)}
                options={options}
            />
        </div>
    )
}

export default PermissionsDropdown