import React from 'react'

const ComponentLoader = () => {
    return (
        <div className='absolute z-50 flex w-full h-full bg-[rgba(250,250,250,0.4)] justify-center items-center'>
            <div className="spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}



export default ComponentLoader