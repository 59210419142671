import { Select } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listUsers } from '../redux/apis/customerApi'
import Loader from '../pages/pages/loader'
import { toast } from 'react-toastify'
import { clearError} from '../redux/slices/customerSlice'
const CustomersDropdown = ({ onChange, onBlur, value, className = "", isAll = false }) => {
    const dispatch = useDispatch()
    const { customers, isLoading, error } = useSelector(state => state.customers)
    useEffect(() => {
        if (!customers) {

            dispatch(listUsers({
                expand: ['country', 'last_updated_by'].join(','),
                page: '*',
                ordering: 'id'
            }))
        }
    }, [])

    let options

    if (customers) {
        options = customers?.map(x => {
            return { label: x.customer_name, value: x.id }
        })
        if (isAll) {
            options = [{ label: "All Customers", value: "*" }, ...options]
        }

    }
    
	useEffect(()=>{
        if ( !error && !isLoading) {
            return
        }
        toast.error(error,{ onclose: ()=>{dispatch(clearError())}})
    },[error, isLoading])

    return (
        <div className=" mb-3">
            <Loader isLoading={isLoading} />
            <label className="" >customer</label>
            <br />
            <Select
                className={className}
                name='customer'
                showSearch
                style={{
                    width: "100%"
                }}
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                defaultValue={value}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                loading={isLoading}
                options={options}
            />
        </div>
    )
}

export default CustomersDropdown