import { createSlice } from '@reduxjs/toolkit'
import { createCountry, createLanguage, listcountry, listLanguage, updateCountry, updateLanguage } from '../apis/utilsApi';


const initialState = {
  contries: null,
  languages: null,
  isLoading: false,
  error: null,
  success: false
}

export const utilsSlice = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    clearError:(state)=>{
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listcountry.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listcountry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contries = action.payload
      })
      .addCase(listcountry.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(createCountry.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCountry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contries = [...state.contries, action.payload]
      })
      .addCase(createCountry.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(updateCountry.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCountry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contries = state.contries.map(x => x.id === action.payload.id ? action.payload : x)
      })
      .addCase(updateCountry.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(listLanguage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listLanguage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.languages = action.payload
      })
      .addCase(listLanguage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(createLanguage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createLanguage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.languages = [...state.languages, action.payload]
      })
      .addCase(createLanguage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(updateLanguage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateLanguage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.languages = state.languages.map(x => x.id === action.payload.id ? action.payload : x)
      })
      .addCase(updateLanguage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
    //------------
  },
})

// Action creators are generated for each case reducer function
export const { clearError} = utilsSlice.actions

export default utilsSlice.reducer