import { createSlice } from '@reduxjs/toolkit'
import { activateUser, createUser, deleteUser, listContentTypes, listUsers, login, resetPassword, UpdateUser } from '../apis/authApi';


const initialState = {
  user: {},
  users: null,
  contentTypes: null,
  isLoading: false,
  error: null,
  success: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null
    },
    clearSuccess: (state) => {
      state.success = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(listUsers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload;
      })
      .addCase(listUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(UpdateUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(UpdateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = state.users.map(x => x.id === action.payload.id ? action.payload : x)
        state.success = true
      })
      .addCase(UpdateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = state.users.map(x => x.id === action.payload.id ? action.payload : x)
        state.success = true
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = [...state.users, action.payload]
        state.success = true
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(activateUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(activateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = state.users.map(x => x.id === action.payload.id ? action.payload : x)
        state.success = true
      })
      .addCase(activateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(listContentTypes.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listContentTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contentTypes = action.payload;
      })
      .addCase(listContentTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
  },
})

// Action creators are generated for each case reducer function
export const { clearError, clearSuccess } = authSlice.actions

export default authSlice.reducer