import { Col, Row } from 'antd';
import React, { useEffect } from 'react'
import { Card, CardBody } from '../other/card/card';
import { useDispatch, useSelector } from 'react-redux';
import { topRemovals } from '../../redux/apis/analyticsApi';
import ComponentLoader from '../other/ComponentLoader';

const TopRemovals = () => {
    const dispatch = useDispatch()
    const { isLoading, topRemovalsCampaigns } = useSelector(state => state.analytics)

    useEffect(() => {
        dispatch(topRemovals())
    }, [])

    const serviceColorLookup = {
        Facebook: '#2a569d',
        Instagram: '#C13584',
        Twitter: '#1DA1F2',
        Youtube: '#FF0000',
        Tiktok: '#000000',
    };
    return (
        <div className="flex-1">
            <Card style={{ height: "450px"}}>
                <CardBody className={"relative p-0"}>

                    {isLoading?.topRemovals ? <ComponentLoader /> : null}
                    <div className='p-4'>

                        <div className="d-flex flex-wrap">
                            <div className="ms-2">
                                <h5 className="card-title mb-3">Top Removals For The Current Year</h5>
                            </div>
                        </div>
                        <Row className="text-center">
                            <Col xs={6}>
                                <div className="mt-3">
                                    <p className="mb-1">Today</p>
                                    <h5>{topRemovalsCampaigns?.today_removals !== null && topRemovalsCampaigns?.today_removals !== undefined ? topRemovalsCampaigns?.today_removals : null}</h5>
                                </div>
                            </Col>

                            <Col xs={6}>
                                <div className="mt-3">
                                    <p className="mb-1">This Month</p>
                                    <h5>
                                        {topRemovalsCampaigns?.this_month_removals !== null && topRemovalsCampaigns?.this_month_removals !== undefined ? topRemovalsCampaigns?.this_month_removals : null}
                                    </h5>
                                </div>
                            </Col>
                        </Row>

                        <hr />

                        <div>
                            <ul className="list-group list-group-flush">
                                {topRemovalsCampaigns?.top_removals ?
                                    Object.entries(topRemovalsCampaigns?.top_removals).map(([service, data]) => (
                                        <li className="list-group-item" key={service}>
                                            <div className="py-2">
                                                <h5 className="font-size-14">
                                                    {service} <span className="float-end">{data}%</span>
                                                </h5>
                                                <div className="progress animated-progess progress-sm">
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{ width: `${data}%`, backgroundColor: serviceColorLookup[service] || '#66BC0F' }}
                                                        aria-valuenow={data}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"

                                                    ></div>
                                                </div>
                                            </div>
                                        </li>
                                    )) : Array(3).fill(0).map((_, i) => {
                                        return (
                                            <li className="list-group-item" key={i}>
                                                <div className="py-2">

                                                    <div className="progress animated-progess progress-sm">
                                                        <div
                                                            className="progress-bar"
                                                            role="progressbar"
                                                            style={{ width: `${(3 - i) * 15}%`, backgroundColor: '#66BC0F' }}
                                                            aria-valuenow={50}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                        ></div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                            </ul>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default TopRemovals