import { Select } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listCampaign } from '../redux/apis/campaignApi'
import Loader from '../pages/pages/loader'
import { clearError } from '../redux/slices/campaignSlice';
import { toast } from 'react-toastify'

const CampaignDropdown = ({ onChange, onBlur, value, activeCampaignsOnly=true, className="" }) => {
    const dispatch = useDispatch()
    const { campaigns, isLoading, error } = useSelector(state => state.campaign)
    useEffect(() => {
        if (!campaigns) {

            dispatch(listCampaign({
                page: '*',
                ordering: 'campaign_name',
                fields: 'id,campaign_name',
                campaign_status: activeCampaignsOnly ? true : null
            }))
        }
    }, [])

    
	useEffect(()=>{
        if ( !error && !isLoading) {
            return
        }
        toast.error(error,{ onclose: ()=>{dispatch(clearError())}})
    },[error, isLoading])

    return (
        <div className=" mb-3">
            <Loader isLoading={isLoading}/>
            <label className="" >Campaign</label>
            <br />
            <Select
                name='campaign'
                className={className}
                showSearch
                style={{
                    width: "100%"
                }}
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                defaultValue={value}
                value={value}
                onChange={e => onChange("campaign", e)}
                onBlur={onBlur}
                loading={isLoading}
                options={campaigns?.map(x => {
                    return { label: x.campaign_name, value: x.id }
                })}
            />
        </div>
    )
}

export default CampaignDropdown