import React, { useContext, useEffect } from 'react';
import { AppSettings } from '../../config/app-settings';
import { Card, CardBody } from './../../components/other/card/card.jsx';
import 'lity';
import 'lity/dist/lity.min.css';
import { formatDateShort } from '../../helpers/date.js';

const Profile = () => {
    const context = useContext(AppSettings);
    const { first_name, last_name, email, date_joined, credit_balance } = JSON.parse(localStorage.getItem("authUser"))
    const videosLength = localStorage.getItem("videosLength")
    useEffect(() => {
        context.setAppHeaderNone(false);
        context.setAppSidebarNone(false);
        context.setAppContentClass('');
        context.setAppThemePanal(true)

        return function cleanUp() {
            context.setAppHeaderNone(true);
            context.setAppSidebarNone(true);
            context.setAppContentClass('p-0');
            context.setAppThemePanal(false)
        };
    }, []);
    return (
        <>
            <Card>
                <CardBody className="p-0">
                    <div className="profile">
                        <div className="profile-container">
                            <div className="profile-sidebar">
                                <div className="desktop-sticky-top">
                                    <div className="profile-img">
                                        <img src="/assets/img/user/profile.jpg" alt="" />
                                    </div>

                                    <h4>{first_name} {last_name}</h4>
                                    <div className="mb-3 text-inverse text-opacity-50 fw-bold mt-n2">@johnsmith</div>
                                    <p>
                                        Principal UXUI Design & Brand Architecture for HUD. Creator of SeanTheme.
                                        Bringing the world closer together. Studied Computer Science and Psychology at Harvard University.
                                    </p>
                                    <div className="mb-1">
                                        <i className="fas fa-envelope fa-fw text-inverse text-opacity-50"></i> {email}
                                    </div>
                                    <div className="mb-1">
                                        <i className="fas fa-money-bill-alt fa-fw text-inverse text-opacity-50"></i> Credit balance: {credit_balance}
                                    </div>
                                    <div className="mb-3">
                                        <i className="far fa-calendar fa-fw text-inverse text-opacity-50"></i> Joined: {formatDateShort(date_joined)}
                                    </div>


                                </div>
                            </div>

                            <div className="profile-content">
                                <ul className="profile-tab nav nav-tabs nav-tabs-v2">
                                    <li className="nav-item">
                                        <a href="#profile-video" className="nav-link" data-bs-toggle="tab">
                                            <div className="nav-field">Videos</div>
                                            <div className="nav-value">{videosLength}</div>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#profile-post" className="nav-link active" data-bs-toggle="tab">
                                            <div className="nav-field">Audios</div>
                                            <div className="nav-value">382</div>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#profile-media" className="nav-link" data-bs-toggle="tab">
                                            <div className="nav-field">Photos</div>
                                            <div className="nav-value">1,397</div>
                                        </a>
                                    </li>
                                </ul>
                                <div className="profile-content-container">
                                    <div className="row gx-4">
                                        <div className="col-xl-12">
                                            <div className="tab-content p-0">
                                                <div className="tab-pane fade show active" id="profile-post">
                                                    <Card className="card mb-3">
                                                        <CardBody>
                                                            <div className="d-flex align-items-center mb-3">
                                                                <a href="#/"><img src="/assets/img/user/profile.jpg" alt="" width="50" className="rounded-circle" /></a>
                                                                <div className="flex-fill ps-2">
                                                                    <div className="fw-bold"><a href="#/" className="text-decoration-none">Clyde Stanley</a> at <a href="#/" className="text-decoration-none">South Lake Tahoe, California</a></div>
                                                                    <div className="small text-inverse text-opacity-50">March 16</div>
                                                                </div>
                                                            </div>

                                                            <p>Best vacation of 2024</p>
                                                            <div className="profile-img-list">
                                                                <div className="profile-img-list-item main"><a href="/assets/img/gallery/gallery-1.jpg" data-lity className="profile-img-list-link"><span className="profile-img-content" style={{ backgroundImage: 'url(/assets/img/gallery/gallery-1.jpg)' }}></span></a></div>
                                                                <div className="profile-img-list-item"><a href="/assets/img/gallery/gallery-2.jpg" data-lity className="profile-img-list-link"><span className="profile-img-content" style={{ backgroundImage: 'url(/assets/img/gallery/gallery-2.jpg)' }}></span></a></div>
                                                                <div className="profile-img-list-item"><a href="/assets/img/gallery/gallery-3.jpg" data-lity className="profile-img-list-link"><span className="profile-img-content" style={{ backgroundImage: 'url(/assets/img/gallery/gallery-3.jpg)' }}></span></a></div>
                                                                <div className="profile-img-list-item"><a href="/assets/img/gallery/gallery-4.jpg" data-lity className="profile-img-list-link"><span className="profile-img-content" style={{ backgroundImage: 'url(/assets/img/gallery/gallery-4.jpg)' }}></span></a></div>
                                                                <div className="profile-img-list-item with-number">
                                                                    <a href="/assets/img/gallery/gallery-5.jpg" data-lity className="profile-img-list-link">
                                                                        <span className="profile-img-content" style={{ backgroundImage: 'url(/assets/img/gallery/gallery-5.jpg)' }}></span>
                                                                        <div className="profile-img-number">+12</div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <hr className="mb-1" />

                                                            <div className="row text-center fw-bold">
                                                                <div className="col">
                                                                    <a href="#/" className="text-inverse text-opacity-50 text-decoration-none d-block p-2">
                                                                        <i className="far fa-thumbs-up me-1 d-block d-sm-inline"></i> Likes
                                                                    </a>
                                                                </div>
                                                                <div className="col">
                                                                    <a href="#/" className="text-inverse text-opacity-50 text-decoration-none d-block p-2">
                                                                        <i className="far fa-comment me-1 d-block d-sm-inline"></i> Comment
                                                                    </a>
                                                                </div>
                                                                <div className="col">
                                                                    <a href="#/" className="text-inverse text-opacity-50 text-decoration-none d-block p-2">
                                                                        <i className="fa fa-share me-1 d-block d-sm-inline"></i> Share
                                                                    </a>
                                                                </div>
                                                            </div>

                                                            <hr className="mb-3 mt-1" />
                                                            <div className="d-flex align-items-center">
                                                                <img src="/assets/img/user/user.jpg" alt="" width="35" className="rounded-circle" />
                                                                <div className="flex-fill ps-2">
                                                                    <div className="position-relative d-flex align-items-center">
                                                                        <input type="text" className="form-control rounded-pill bg-inverse bg-opacity-10" style={{ paddingRight: '120px' }} placeholder="Write a comment..." />
                                                                        <div className="position-absolute end-0 text-center">
                                                                            <a href="#/" className="text-inverse text-opacity-50 me-2"><i className="fa fa-smile"></i></a>
                                                                            <a href="#/" className="text-inverse text-opacity-50 me-2"><i className="fa fa-camera"></i></a>
                                                                            <a href="#/" className="text-inverse text-opacity-50 me-2"><i className="fa fa-video"></i></a>
                                                                            <a href="#/" className="text-inverse text-opacity-50 me-3"><i className="fa fa-paw"></i></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>)
}

export default Profile