import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

function PerformanceChart({ data }) {
    const [insertedSeries, setInsertedSeries] = useState([]);
    const [dataCategories, setDataCategories] = useState([]);

    const themeFont = (getComputedStyle(document.body).getPropertyValue('--bs-body-font-family')).trim();
    const themeFontWeight = (getComputedStyle(document.body).getPropertyValue('--bs-body-font-weight')).trim();
    const inverse = (getComputedStyle(document.body).getPropertyValue('--bs-inverse')).trim();
    const inverseRgb = (getComputedStyle(document.body).getPropertyValue('--bs-inverse-rgb')).trim();
    const borderColor = "#ddd";

    useEffect(() => {
        if (data) {
            let tmpCategories = [];
            let tmpInsertedSeries = [];
            let InsertedByOperatorMap = {};
            Object.entries(data).forEach(([date, operators]) => {
                tmpCategories.push(convertDateToString(date));
                Object.entries(operators).forEach(([operator, numberOfInsertedLinks]) => {
                    if (!InsertedByOperatorMap[operator]) {
                        InsertedByOperatorMap[operator] = [];
                    }
                    InsertedByOperatorMap[operator].push(numberOfInsertedLinks);
                })
            })
            Object.entries(InsertedByOperatorMap).forEach(([operator, data]) => {
                tmpInsertedSeries.push({
                    name: operator,
                    data: data
                })
            })
            setInsertedSeries(tmpInsertedSeries);
            setDataCategories(tmpCategories);
        }
    }, [data])

    // area chart
    const areaChartOptions = {
        dataLabels: { enabled: false },
        stroke: { curve: 'smooth', width: 1 },
        grid: { borderColor: borderColor },
        xaxis: {
            type: 'datetime',
            categories: dataCategories,
            axisBorder: { show: true, color: 'rgba(' + inverseRgb + ', .25)', height: 1, width: '100%', offsetX: 0, offsetY: -1 },
            axisTicks: { show: true, borderType: 'solid', color: 'rgba(' + inverseRgb + ', .25)', height: 6, offsetX: 0, offsetY: 0 },
            labels: { style: { colors: inverse, fontSize: '12px', fontFamily: themeFont, fontWeight: themeFontWeight, cssClass: 'apexcharts-xaxis-label' } }
        },
        plotOptions: {
            bar: {
                columnWidth: "50%",
                borderRadius: 0,
                colors: {
                    // Set the color to transparent to remove the border
                    backgroundBarColors: ["transparent"],
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: inverse,
                    fontSize: '12px',
                    fontFamily: themeFont,
                    fontWeight: themeFontWeight,
                    cssClass: 'apexcharts-xaxis-label'
                }
            }
        },
        fill: {
            opacity: 1,
        },
        legend: { fontFamily: themeFont, labels: { colors: inverse } },
        tooltip: { x: { format: 'dd/MM/yy HH:mm' } }
    };


    return (
        <div>
            {insertedSeries.length > 0 && dataCategories.length > 0 ? (
                <Chart type="area" height={500} options={areaChartOptions} series={insertedSeries} />
            ) : null}
        </div>
    )
}

export default PerformanceChart;

const convertDateToString = (date) => {
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);
    let monthName = new Date(year, month - 1).toLocaleString('en-us', { month: 'long' });
    // shorten the month name
    monthName = monthName.slice(0, 3); // January -> Jan
    // shorten the year
    let yearShort = year.slice(2, 4); // 2021 -> 21
    return day + ' ' + monthName + ' ' + yearShort;
};