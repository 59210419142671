import axiosInstance from '../../helpers/conf'
import { createAsyncThunk } from '@reduxjs/toolkit';


export const listCampaign = createAsyncThunk(
  'campaign/listCampaign',
  async (params) => {
    const response = await axiosInstance.get(`campaigns/`, {params:{...params}});
    return response.results;
  }
);

export const retrieveCampaign = createAsyncThunk(
  'campaign/retrieveCampaign',
  async ({id, params}) => {
    const response = await axiosInstance.get(`campaigns/${id}/`, {params:{...params}});
    return response;
  }
);

export const createCampaign = createAsyncThunk(
  'campaign/createCampaign',
  async (data) => {
    const response = await axiosInstance.post(`campaigns/?expand=user,last_updated_by,customer,platforms`, data);
    return response;
  }
);
export const updateCampaign = createAsyncThunk(
  'campaign/updateCampaign',
  async ({data, id}) => {
    const response = await axiosInstance.patch(`campaigns/${id}/?expand=user,last_updated_by,customer,platforms`, data);
    return response;
  }
);
export const getLoa = createAsyncThunk(
  'campaign/getLoa',
  async (params) => {
    const response = await axiosInstance.get(`campaigns/loa/`, params);
    return response.results;
  }
);
export const updateLoa = createAsyncThunk(
  'campaign/updateLoa',
  async (data) => {
    const response = await axiosInstance.patch(`campaigns/loa/`, data);
    return response;
  }
);

//---------------------------------


export const listCampaignsType = createAsyncThunk(
  'campaign/listCampaignsType',
  async (params) => {
    const response = await axiosInstance.get(`campaigns/types/`, params);
    return response.results;
  }
);
export const createCampaignsType = createAsyncThunk(
  'campaign/createCampaignsType',
  async (data) => {
    const response = await axiosInstance.post(`campaigns/types`, data);
    return response.results;
  }
);
export const updateCampaignsType = createAsyncThunk(
  'campaign/updateCampaignsType',
  async (data, id) => {
    const response = await axiosInstance.patch(`campaigns/types/${id}/`, data);
    return response.results;
  }
);

//---------------------------

export const listCampaignsPlatform = createAsyncThunk(
  'campaign/listCampaignsPlatform',
  async (params) => {
    const response = await axiosInstance.get(`campaigns/platforms/`, {params:{...params}});
    return response.results;
  }
);
export const createCampaignsPlatform = createAsyncThunk(
  'campaign/createCampaignsPlatform',
  async (data) => {
    const response = await axiosInstance.post(`campaigns/platforms/`, data);
    return response.results;
  }
);
export const updateCampaignsplatform = createAsyncThunk(
  'campaign/updateCampaignsplatform',
  async ({data, id}) => {
    const response = await axiosInstance.patch(`campaigns/platforms/${id}/`, data);
    return response.results;
  }
);
export const deleteCampaignsplatform = createAsyncThunk(
  'campaign/deleteCampaignsplatform',
  async (id) => {
    const response = await axiosInstance.delete(`campaigns/platforms/${id}/`);
    return response.results;
  }
);

//-----------------


export const listCampaignsLanguages = createAsyncThunk(
  'campaign/listCampaignsLanguages',
  async (params) => {
    const response = await axiosInstance.get(`campaigns/languages/`, params);
    return response.results;
  }
);
export const createCampaignsLanguages = createAsyncThunk(
  'campaign/createCampaignsLanguages',
  async (data) => {
    const response = await axiosInstance.post(`campaigns/languages/`, data);
    return response.results;
  }
);
export const updateCampaignsLanguages = createAsyncThunk(
  'campaign/updateCampaignsLanguages',
  async (data, id) => {
    const response = await axiosInstance.patch(`campaigns/languages/${id}/`, data);
    return response.results;
  }
);
export const deleteCampaignsLanguages = createAsyncThunk(
  'campaign/deleteCampaignsLanguages',
  async (id) => {
    const response = await axiosInstance.delete(`campaigns/languages/${id}/`);
    return response.results;
  }
);

//--------------------------------------