import { createSlice } from '@reduxjs/toolkit'
import { createCampaign, createCampaignsPlatform, createCampaignsType, deleteCampaignsplatform, getLoa, listCampaign, listCampaignsPlatform, listCampaignsType, retrieveCampaign, updateCampaign, updateCampaignsplatform, updateCampaignsType, updateLoa } from '../apis/campaignApi';


const initialState = {
  campaigns: null,
  campaign:null,
  loa: null,
  campaignsType: null,
  campaignsPlatform: null,
  isLoading: false,
  error: null,
  success: false
}

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    clearCampaigns:(state)=>{
      state.campaigns = null
    },
    clearError:(state)=>{
      state.error = null
    },
    clearSuccess:(state)=>{
      state.success = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listCampaign.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listCampaign.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaigns = action.payload
      })
      .addCase(listCampaign.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(retrieveCampaign.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(retrieveCampaign.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaign = action.payload
      })
      .addCase(retrieveCampaign.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(createCampaign.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCampaign.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaigns = [action.payload,...state.campaigns]
        state.success = true
      })
      .addCase(createCampaign.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(updateCampaign.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCampaign.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaigns = state.campaigns.map(x => x.id === action.payload.id ? action.payload : x)
        state.success = true
      })
      .addCase(updateCampaign.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(getLoa.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getLoa.fulfilled, (state, action) => {
        state.isLoading = false;
        state.loa = action.payload
      })
      .addCase(getLoa.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(updateLoa.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateLoa.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true
      })
      .addCase(updateLoa.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(listCampaignsType.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listCampaignsType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaignsType = action.payload
      })
      .addCase(listCampaignsType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(createCampaignsType.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCampaignsType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaignsType = [...state.campaignsType, action.payload]
      })
      .addCase(createCampaignsType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(updateCampaignsType.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCampaignsType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaignsType = state.campaignsType.map(x => x.id === action.payload.id ? action.payload : x)
      })
      .addCase(updateCampaignsType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-------------
      .addCase(listCampaignsPlatform.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listCampaignsPlatform.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaignsPlatform = action.payload
      })
      .addCase(listCampaignsPlatform.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(createCampaignsPlatform.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCampaignsPlatform.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaignsPlatform = [...state.campaignsPlatform, action.payload]
      })
      .addCase(createCampaignsPlatform.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(updateCampaignsplatform.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCampaignsplatform.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaignsPlatform = state.campaignsPlatform.map(x => x.id === action.payload.id ? action.payload : x)
      })
      .addCase(updateCampaignsplatform.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(deleteCampaignsplatform.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteCampaignsplatform.fulfilled, (state, action) => {
        state.isLoading = false;
        state.campaignsPlatform = state.campaignsPlatform.filter(x => x.id !== action.payload.id)
      })
      .addCase(deleteCampaignsplatform.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
  },
})

// Action creators are generated for each case reducer function
export const { clearCampaigns, clearError, clearSuccess} = campaignSlice.actions

export default campaignSlice.reducer