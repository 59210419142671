import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import youtubeLogo from '../../../assets/services/youtube_logo.webp';
import facebookLogo from '../../../assets/services/facebook_logo.png';
import tiktokLogo from '../../../assets/services/tiktok_logo.png';
import instagramLogo from '../../../assets/services/instagram_logo.png';
import twitterLogo from '../../../assets/services/twitter_logo.png';
import twitchLogo from '../../../assets/services/twitch_logo.png';
import snapchatLogo from '../../../assets/services/snapchat_logo.webp';
import { perPlatformMonthDetectedReportedRemoved } from '../../../redux/apis/analyticsApi';
import { Card, CardBody, CardExpandToggler } from '../../other/card/card';
import ComponentLoader from '../../other/ComponentLoader';

const serviceLogos = {
    Tiktok: tiktokLogo,
    Youtube: youtubeLogo,
    Facebook: facebookLogo,
    Instagram: instagramLogo,
    Twitter: twitterLogo,
    Twitch: twitchLogo,
    Snapchat: snapchatLogo,
};

const ServiceCard = ({ title, reported_links, removal_rate, photo }) => (
    <Card>
        <CardBody className="p-2 pt-4" style={{ minHeight: "210px", width: '100%', marginRight: "5px" }}>
            <div className="flex flex-col items-center mb-3">
                <img src={photo} alt={title} className="w-8 h-8" />
                <h5 className="mt-4 mb-2 font-size-15">{title.toUpperCase()}</h5>
                <p className="mb-0"><b>{reported_links}</b> Reported Links</p>
                <p className="mb-0"><b>{removal_rate % 1 === 0 ? Math.round(removal_rate) : removal_rate}%</b> REMOVAL SUCCESS RATE</p>
            </div>
        </CardBody>
    </Card>
);

const PlatformsInsights = ({campaign_id}) => {
    const dispatch = useDispatch()
    const [servicesInfo, setServicesInfo] = useState([]);
    const { isLoading, perPlatformMonthDetectedReportedRemovedCampaigns } = useSelector(state => state.analytics);

useEffect(()=>{
    dispatch(perPlatformMonthDetectedReportedRemoved({ campaign_id: campaign_id }))

},[])

    useEffect(() => {
        if(!perPlatformMonthDetectedReportedRemovedCampaigns) return
        const updatedServicesInfo = Object.keys(serviceLogos).map(service => ({
            title: service,
            reported_links: perPlatformMonthDetectedReportedRemovedCampaigns[service]?.overall?.reported || 0,
            removal_rate: perPlatformMonthDetectedReportedRemovedCampaigns[service]?.overall?.removal_rate || 0,
            photo: serviceLogos[service],
        }));

        setServicesInfo(updatedServicesInfo);
    }, [perPlatformMonthDetectedReportedRemovedCampaigns]);

    const loadingCards = Object.entries(serviceLogos).map(([key, value]) => (
        <ServiceCard key={key} title={key} reportedLinks={0} removalRate={0} photo={value} />
    ));
    return (
        <div className="col-xl-12 col-lg-6">
            <Card className="mb-3">
                <CardBody className="relative p-0">
                    {isLoading?.perPlatformMonthDetectedReportedRemoved ? <ComponentLoader /> : null}
                    <div className='p-4'>
                        <div className="d-flex fw-bold small mb-3">
                            <span className="flex-grow-1">Platforms Insights</span>
                            {/* <CardExpandToggler /> */}
                        </div>
                        <div className='flex flex-wrap justify-center gap-8'>
                            {perPlatformMonthDetectedReportedRemovedCampaigns ? (
                                servicesInfo.map(item => (
                                    <ServiceCard key={item.title} {...item} />
                                ))
                            ) : loadingCards}
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default PlatformsInsights;
