import axiosInstance from '../../helpers/conf'
import { createAsyncThunk } from '@reduxjs/toolkit';


export const numberOfCampaigns = createAsyncThunk(
  'analytics/numberOfCampaigns',
  async (params) => {
    const response = await axiosInstance.get(`analytics/number-of-campaigns/`, params);
    return response;
  }
);
export const detectedReportedRemoved = createAsyncThunk(
  'analytics/detectedReportedRemoved',
  async ({campaign_id}) => {
    const response = await axiosInstance.get(`analytics/detected-reported-removed/?campaign_id=${campaign_id}`);
    return response;
  }
);
export const perCustomerDetectedReportedRemoved = createAsyncThunk(
  'analytics/perCustomerDetectedReportedRemoved',
  async (params) => {
    const response = await axiosInstance.get(`analytics/per-customer-detected-reported-removed/`, params);
    return response;
  }
);
export const perPlatformMonthDetectedReportedRemoved = createAsyncThunk(
  'analytics/perPlatformMonthDetectedReportedRemoved',
  async ({campaign_id}) => {
    const response = await axiosInstance.get(`analytics/per-platform-month-detected-reported-removed/?campaign_id=${campaign_id}`);
    return response;
  }
);
export const topCampaignsDetectedReportedRemoved = createAsyncThunk(
  'analytics/topCampaignsDetectedReportedRemoved',
  async (params) => {
    const response = await axiosInstance.get(`analytics/top-campaigns-detected-reported-removed/`, params);
    return response;
  }
);
export const dailyDetectedReportedRemoved = createAsyncThunk(
  'analytics/dailyDetectedReportedRemoved',
  async (params) => {
    const response = await axiosInstance.get(`analytics/daily-detected-reported-removed/`, {params : {...params}});
    return response;
  }
);
export const topRemovals = createAsyncThunk(
  'analytics/topRemovals',
  async (params) => {
    const response = await axiosInstance.get(`analytics/top-removals/`, {params:{...params}});
    return response;
  }
);
export const opsPerformance = createAsyncThunk(
  'analytics/opsPerformance',
  async (params) => {
    const response = await axiosInstance.get(`analytics/ops-performance/`, {params:{...params}});
    return response;
  }
);

//-----------------------------------

export const reportsOpsPerformance = createAsyncThunk(
  'reports/reportsOpsPerformance',
  async (params) => {
    const response = await axiosInstance.get(`reports/ops-performance/`, {params:{...params}});
    return response;
  }
);


//-----------------------

export const backgroundTasks = createAsyncThunk(
  'tasks/backgroundTasks',
  async (params) => {
    const response = await axiosInstance.get(`tasks/`, {params:{...params}});
    return response.results;
  }
);