import { Link } from "react-router-dom";

export default function NoPermission() {
    return (
        <>
            <main className="grid min-h-full place-items-center bg-transparent px-6 py-24 sm:py-32 lg:px-8">
                <div className="text-center">
                    <h2 className="mt-4 text-balance  font-semibold tracking-tight ">
                        You dont have permissions to view this page
                    </h2>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Link to={"/dashboard"} className="btn btn-outline-theme">Go To Dashboard</Link>
                    </div>
                </div>
            </main>
        </>
    )
}
