import { createSlice } from '@reduxjs/toolkit'
import { assignGroupToUser, assignPermissionToGroup, assignPermissionToUser, bulkDeleteGroupFromUser, bulkDeletePermissionFromGroup, bulkDeletePermissionFromUser, createGroup, createPermission, deleteGroupFromUser, deletePermissionFromGroup, deletePermissionFromUser, listContentTypes, listGroupPermissions, listGroups, listPermissions, listUserGroups, listUserPermissions, updateGroup, updatePermission } from '../apis/authApi';


const initialState = {
  permissions: null,
  groups: null,
  groupPermissions: null,
  userGroups: null,
  userpermissions: null,
  contentTypes: null,
  isLoading: false,
  error: null,
  success: false
}

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null
    },
    clearSuccess: (state) => {
      state.success = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listPermissions.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listPermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.permissions = action.payload;
      })
      .addCase(listPermissions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(createPermission.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createPermission.fulfilled, (state, action) => {
        state.isLoading = false;
        state.permissions = [action.payload, ...state.permissions]
        state.success = true
      })
      .addCase(createPermission.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(updatePermission.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePermission.fulfilled, (state, action) => {
        state.isLoading = false;
        state.permissions = state.permissions.map(x => x.id == action.payload.id ? action.payload : x);
        state.success = true
      })
      .addCase(updatePermission.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(listGroups.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listGroups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.groups = action.payload;
      })
      .addCase(listGroups.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(createGroup.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.groups = [action.payload, ...state.groups]
        state.success = true
      })
      .addCase(createGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(updateGroup.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.groups = state.groups.map(x => x.id === action.payload.id ? action.payload : x);
        state.success = true
      })
      .addCase(updateGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(listGroupPermissions.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listGroupPermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.groupPermissions = action.payload;
      })
      .addCase(listGroupPermissions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(assignPermissionToGroup.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(assignPermissionToGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.groupPermissions = state.groupPermissions.map(x => x.id === action.payload.id ? action.payload : x);
      })
      .addCase(assignPermissionToGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(deletePermissionFromGroup.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deletePermissionFromGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.groupPermissions = state.groupPermissions.filter(x => x.id !== action.payload.id);
      })
      .addCase(deletePermissionFromGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(bulkDeletePermissionFromGroup.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(bulkDeletePermissionFromGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.groupPermissions = action.payload
      })
      .addCase(bulkDeletePermissionFromGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(listUserGroups.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listUserGroups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userGroups = action.payload;
      })
      .addCase(listUserGroups.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(assignGroupToUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(assignGroupToUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userGroups = state.userGroups.map(x => x.id === action.payload.id ? action.payload : x);
      })
      .addCase(assignGroupToUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(deleteGroupFromUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteGroupFromUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userGroups = state.userGroups.filter(x => x.id !== action.payload.id);
      })
      .addCase(deleteGroupFromUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(bulkDeleteGroupFromUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(bulkDeleteGroupFromUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userGroups = action.payload
      })
      .addCase(bulkDeleteGroupFromUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(listUserPermissions.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listUserPermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userpermissions = action.payload;
      })
      .addCase(listUserPermissions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(assignPermissionToUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(assignPermissionToUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userpermissions = state.userpermissions.map(x => x.id === action.payload.id ? action.payload : x);
      })
      .addCase(assignPermissionToUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(deletePermissionFromUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deletePermissionFromUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userpermissions = state.userpermissions.filter(x => x.id !== action.payload.id);
      })
      .addCase(deletePermissionFromUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(bulkDeletePermissionFromUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(bulkDeletePermissionFromUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userpermissions = action.payload
      })
      .addCase(bulkDeletePermissionFromUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //-----
      .addCase(listContentTypes.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listContentTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contentTypes = action.payload
      })
      .addCase(listContentTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
  },
})

// Action creators are generated for each case reducer function
export const { clearError, clearSuccess } = permissionsSlice.actions

export default permissionsSlice.reducer