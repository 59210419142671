import React from 'react'
import { Card, CardBody, CardHeader } from '../../other/card/card'

const DetailsHeader = ({data}) => {
    return (
        <Card>
            <CardHeader className="flex gap-4 items-center">
                <img
                    src={data?.customer?.customer_logo_image_thumbnail}
                    alt=""
                    className="avatar-md rounded-circle me-3"
                    style={{
                        width: "50px",
                        height: "50px",
                    }}
                />
                <h3>{data?.customer?.customer_name}</h3>
            </CardHeader>
            <CardBody className="flex justify-between items-center">
                <div>

                    <h5>campaign details:</h5>
                    <br />
                    <div className='flex gap-2' >
                        <i class="fas fa-angle-right"></i>
                        <p className='font-bold'>campaign Name :</p>
                        <p> {data?.campaign_name}</p>
                    </div>
                    <div className='flex gap-2' >
                        <i class="fas fa-angle-right"></i>
                        <p className='font-bold'>Campaign Status :</p>
                        <p> {data?.campaign_status === true ? "Active" : "Inactive"}</p>
                    </div>
                    <div className='flex gap-2' >
                        <i class="fas fa-angle-right"></i>
                        <p className='font-bold'>Country :</p>
                        <p> {data?.country?.country_name}</p>
                    </div>
                    <div className='flex gap-2' >
                        <i class="fas fa-angle-right"></i>
                        <p className='font-bold'>Sample Of Work :</p>
                        <a href={data?.sample_of_work}
                            target="_blank"
                            rel="noreferrer"
                        >Click here</a>
                    </div>
                </div>
                <div>
                    <img
                        src={data?.campaign_poster_path}
                        alt=""
                        style={{
                            maxHeight: "525px",
                            height: "320px",
                            width: "100%",
                            objectPosition: "center"
                        }}
                    />
                </div>
            </CardBody>
        </Card>
    )
}

export default DetailsHeader