import React, { useContext, useEffect, useState } from 'react';
import { AppSettings } from '../../config/app-settings';
import Loader from '../pages/loader';
import ReusableTable from '../../components/other/ReuseableTable';
import { useDispatch, useSelector } from 'react-redux';
import { listUsers } from '../../redux/apis/authApi';
import EditCreateUser from '../../components/users/EditCreateUser';
import DeactivateUser from '../../components/users/DeactivateUser';
import { can } from '../../helpers/auth';
import NoPermission from '../pages/noPermission';
import { toast } from 'react-toastify';
import { clearError, clearSuccess } from '../../redux/slices/authSlice';

function Users() {
	const dispatch = useDispatch()
	const { isLoading, users, error, success } = useSelector(state => state.auth)
	const [edit, setEdit] = useState(false)
	const [create, setCreate] = useState(false)
	const [deactivate, setDeactivate] = useState(false)
	const [holder, setHolder] = useState()

	useEffect(() => {
		if (!users || !users?.[0]?.email) {
			dispatch(listUsers())
		}
	}, [])

	let columns = [
		{ title: "ID", dataIndex: "id", key: "id", sorter: true },
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			sorter: true,
			search: true,
		},

		{
			title: 'First Name',
			dataIndex: 'first_name',
			key: 'first_name',
			sorter: true,
			search: true,
		},
		{
			title: 'Last Name',
			dataIndex: 'last_name',
			key: 'last_name',
			sorter: true,
			search: true,
		},
		{
			title: 'Is Active',
			dataIndex: 'is_active',
			key: 'is_active',
			filters: [
				{ text: 'Yes', value: true },
				{ text: 'No', value: false },
			],
			onFilter: (value, record) => record.is_active === value,
			render: (text) => (text ? "Yes" : "No"),

		},
		{
			title: 'Is Admin',
			dataIndex: 'is_superuser',
			key: 'is_superuser',
			filters: [
				{ text: 'Yes', value: true },
				{ text: 'No', value: false },
			],
			onFilter: (value, record) => record.is_superuser === value,
			render: (text) => (text ? "Yes" : "No"),
		},
		{
			title: 'Is Staff',
			dataIndex: 'is_staff',
			key: 'is_staff',
			filters: [
				{ text: 'Yes', value: true },
				{ text: 'No', value: false },
			],
			onFilter: (value, record) => record.is_staff === value,
			render: (text) => (text ? "Yes" : "No"),
		},
		
	];

	if(can("change_user")){
		columns.push({
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<div className='flex gap-2'>
					<button className='btn btn-outline-theme' onClick={() => handleEdit(record)}>Edit</button>
					<button className='btn btn-outline-light' onClick={() => handleDeactivate(record)}>{record.is_active?"Deactivate":"Activate"}</button>
				</div>
			),
		})
	}

	const handleEdit = (record) => {
		setHolder(record)
		setEdit(true)
	};
	const handleDeactivate = (record) => {
		setHolder(record)
		setDeactivate(true)
	};

	const context = useContext(AppSettings);
	useEffect(() => {
		context.setAppHeaderNone(false);
		context.setAppSidebarNone(false);
		context.setAppContentClass('');
		context.setAppThemePanal(true)

		return function cleanUp() {
			context.setAppHeaderNone(true);
			context.setAppSidebarNone(true);
			context.setAppContentClass('p-0');
			context.setAppThemePanal(false)
		};
	}, []);

	useEffect(()=>{
        if ( !error && !isLoading) {
            return
        }
        toast.error(error,{ onclose: ()=>{dispatch(clearError())}})
    },[error, isLoading])

	
    useEffect(() => {
        if (success) {
            toast.success("Action successfuly", { onclose: () => { dispatch(clearSuccess()) } })
        }
      }, [success])

	if (!can("view_user")) {
        return <NoPermission />
    }

	return (
		<div className='w-full flex flex-col'>
			<Loader isLoading={isLoading} />
			{edit ? <EditCreateUser state={"edit"} open={edit} setOpen={setEdit} user={holder} /> : null}
			{create ? <EditCreateUser state={"create"} open={create} setOpen={setCreate} user={null} /> : null}
			{deactivate ? <DeactivateUser  open={deactivate} setOpen={setDeactivate} user={holder} /> : null}
			<button className='btn btn-outline-theme ml-auto' onClick={() => setCreate(true)}>Add New User</button>
			<br/>
			{users ?
				<ReusableTable
					data={users}
					columns={columns}
				/>
				: null
			}
		</div>
	)
}

export default Users;