import React, { useContext, useEffect } from 'react';
import { AppSettings } from '../../config/app-settings';
import { Card, CardBody, CardFooter, CardHeader } from '../../components/other/card/card'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateVideoBulk } from '../../redux/apis/videosApi';
import { Select } from 'antd';
import Loader from '../pages/loader'
import { toast } from 'react-toastify';
import { can } from '../../helpers/auth';
import NoPermission from '../pages/noPermission';
import { clearBulkInsertion, clearError } from '../../redux/slices/videoSlice';


function SocialMediaLinkMarking() {
	const dispatch = useDispatch()
	const { isLoading, bulkInsertion, error } = useSelector(state => state.video)


	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			url: '',
			link_type: '',
			report_date: null,
			remove_date: null,
		},
		validationSchema: Yup.object({
			url: Yup.string().required('Please enter URLs'),
			link_type: Yup.string().required('Please select a links status'),
		}),


		onSubmit: (values, { resetForm }) => {



			// Make API call or perform desired action with the values
			const urls = values['url'].split('\n')
			// loop through groupedUrls and create a body for each url in each domain

			let details = {
				"link_type": values['link_type'],
				"report_date": values['report_date'],
				"remove_date": values['remove_date']
			}

			if (values['link_type'] === "removed" || values['link_type'] === "reported") {
				delete details.link_type

			} else {
				delete details.remove_date
				delete details.report_date
			}
			let body = urls.map((x) => {
				return {
					url: x,
					...details
				}
			})
			if (Object.keys(validation.errors).length > 0) {
				Object.keys(validation.errors).forEach((error) => {
					toast.error(validation.errors[error]);
				});
			}

			if (Object.keys(validation.errors).length === 0) {
				dispatch(updateVideoBulk(body))
				body = {};
				resetForm();
			}
		}

	});

	useEffect(()=>{
		if(!bulkInsertion)return
		if (bulkInsertion?.invalid == 0) {
            toast.success(`Urls marked successfully, \n "marked": ${bulkInsertion?.marked},
                "already reported": ${bulkInsertion?.already_reported} \n
				"already removed" : ${bulkInsertion?.already_removed}.`,
                { onclose: () => { dispatch(clearBulkInsertion()) } }
            )
        }
	},[bulkInsertion])

	useEffect(()=>{
        if (Object.keys(validation.errors).length === 0 && !error && !isLoading) {
            validation.resetForm();
            return
        }
        toast.error(error,{ onclose: ()=>{dispatch(clearError())}})
    },[error, isLoading])

	const handleReportStatusChange = (event) => {
		const value = event;
		validation.setFieldValue('link_type', value);
		if (value === 'reported') {
			validation.setFieldValue('report_date', new Date().toISOString().split('T')[0]);
			validation.setFieldValue('remove_date', null);
		}
		else if (value === 'removed') {
			validation.setFieldValue('report_date', new Date().toISOString().split('T')[0]);
			validation.setFieldValue('remove_date', new Date().toISOString().split('T')[0]);
		}
		else if (value === 'retracted') {
			validation.setFieldValue('report_date', null);
			validation.setFieldValue('remove_date', null);

		}
		else if (value === 'monetized') {
			validation.setFieldValue('report_date', null);
			validation.setFieldValue('remove_date', null);

		}
		else if (value === '' || value === null || value === undefined || value === 'Select an option' || value === ' ') {
			validation.setFieldValue('report_date', null);
			validation.setFieldValue('remove_date', null);
		}
	};

	const context = useContext(AppSettings);
	useEffect(() => {
		context.setAppHeaderNone(false);
		context.setAppSidebarNone(false);
		context.setAppContentClass('');
		context.setAppThemePanal(true)

		return function cleanUp() {
			context.setAppHeaderNone(true);
			context.setAppSidebarNone(true);
			context.setAppContentClass('p-0');
			context.setAppThemePanal(false)
		};
	}, []);

	if (!can("change_videourl")) {
        return <NoPermission />
    }
	
	return (
		<div>
			<Loader isLoading={isLoading} />
			<Card>
				<CardHeader>
					Social Media Marking
				</CardHeader>
				<CardBody>
					<div className="mb-3">
						<label htmlFor='url'>URLs (separated by new lines)</label>
						<br />
						<textarea
							name="url"
							value={validation.values.url}
							onChange={validation.handleChange}
							onBlur={validation.handleBlur}
							placeholder='Enter url'
							style={{ height: "250px", width: '100%', backgroundColor: "transparent", border: '1px solid white', padding: "8px" }}
						/>
						{validation.touched.url && validation.errors.url && (
							<p className='text-danger'>{validation.errors.url}</p>
						)}
					</div>
					<div className='mb-3'>
						<label htmlFor='link_type'>Report Status</label>
						<br />
						<Select
							className="customSelect"
							name='link_type'
							style={{
								width: "100%"
							}}

							defaultValue={validation.values.link_type}
							value={validation.values.link_type}
							onChange={handleReportStatusChange}
							onBlur={validation.handleBlur}
							options={[
								{ label: "Reported", value: "reported" },
								{ label: "Removed", value: "removed" },
								{ label: "Retracted", value: "retracted" },
								{ label: "Monetized", value: "monetized" },
							]}
						/>
						{validation.touched.link_type && validation.errors.link_type && (
							<p className='text-danger'>{validation.errors.link_type}</p>
						)}
					</div>

				</CardBody>
				<CardFooter>
					<div className='flex gap-4'>
						<button type="submit" className="btn btn-outline-theme" onClick={(e) => validation.handleSubmit()}
							disabled={isLoading}
						>
							Submit
						</button>
					</div>
				</CardFooter>
			</Card>
		</div>
	)
}

export default SocialMediaLinkMarking;