import axiosInstance from '../../helpers/conf'
import { createAsyncThunk } from '@reduxjs/toolkit';


export const listWhiteList = createAsyncThunk(
  'whitelist/listWhiteList',
  async (params) => {
    const response = await axiosInstance.get(`whitelist-channels/`, {params:{...params}});
    return response.results;
  }
);
export const createWhitelist = createAsyncThunk(
  'whitelist/createWhitelist',
  async (data) => {
    const response = await axiosInstance.post(`whitelist-channels/?expand=user,last_updated_by,customer`, data);
    return response;
  }
);
export const updateWhitelist = createAsyncThunk(
  'whitelist/updateWhitelist',
  async ({data, id}) => {
    const response = await axiosInstance.patch(`whitelist-channels/${id}/?expand=user,last_updated_by,customer`, data);
    return response;
  }
);

