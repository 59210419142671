import React from 'react'
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Input, Modal, Select } from 'antd';
import ContentTypesDropdown from '../ContentTypesDropdown';
import { createPermission, updatePermission } from '../../redux/apis/authApi';
import { toast } from 'react-toastify';

const EditCreatePermissions = ({ state, open, setOpen, permission }) => {
    const dispatch = useDispatch()

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: permission ? permission.name : "",
            codename: permission ? permission.codename : "",
            content_type: permission ? permission.content_type : null,
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            codename: Yup.string().required('Code is required'),
            content_type: Yup.string().required('Content Type is required'),
        }),
        onSubmit: (values) => {
            if (state === "edit") {
                dispatch(updatePermission({ data: values, id: permission?.id }))
            }
            if (state === "create") {
                dispatch(createPermission(values))
            }
            if (Object.keys(validation.errors).length === 0) {
                setOpen(false)
            }
        },
    })

    return (
        <Modal
            className='customModal'
            open={open}
            title={state === "edit" ? "Edit Permission" : "Add New Permission"}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={() => {
                    if (Object.keys(validation.errors).length !== 0) {
                        Object.keys(validation.errors).map((key) => {
                            toast.error(validation.errors[key]);
                        });
                        return;
                    }
                    validation.handleSubmit();
                }}>
                    Submit
                </Button>
            ]}
        >
            <div className=" mb-3">
                <label className="" >Name</label>
                <Input
                    required
                    type="name"
                    name='name'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name}
                />
                {validation.touched.name &&
                    validation.errors.name ? (
                    <p className='text-danger'>
                        {validation.errors.name}
                    </p>
                ) : null}
            </div>
            <div className=" mb-3">
                <label className="" >Permission Code</label>
                <Input
                    required
                    type="text"
                    name='codename'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.codename}
                />
                {validation.touched.codename &&
                    validation.errors.codename ? (
                    <p className='text-danger'>
                        {validation.errors.codename}
                    </p>
                ) : null}
            </div>
            <div>
                <ContentTypesDropdown
                    onBlur={validation.handleBlur}
                    onChange={validation.setFieldValue}
                    value={validation.values.content_type}
                    multi={false}
                />
                {validation.touched.content_type && validation.errors.content_type ? (
                    <p className='text-danger'>{validation.errors.content_type}</p>
                ) : null}
            </div>
        </Modal>
    )
}

export default EditCreatePermissions