import { createSlice } from '@reduxjs/toolkit'
import { bulkCreatePlatforms, createCustomer, listCustomerPlatforms, listUsers, updateCustomer } from '../apis/customerApi';


const initialState = {
  customers: null,
  platforms: null,
  isLoading: false,
  error: null,
  success: false
}

export const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null
    },
    clearSuccess: (state) => {
      state.success = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listUsers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customers = action.payload
      })
      .addCase(listUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = "Error getting Customers";
      })
      //------------
      .addCase(listCustomerPlatforms.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listCustomerPlatforms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.platforms = action.payload
      })
      .addCase(listCustomerPlatforms.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(bulkCreatePlatforms.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(bulkCreatePlatforms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true
      })
      .addCase(bulkCreatePlatforms.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(createCustomer.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customers = [action.payload, ...state.customers]
        state.success = true
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(updateCustomer.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customers = state.customers.map((x) =>
          x.id === action.payload.id ? action.payload : x
        );
        state.success = true
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
    //------------
  },
})

// Action creators are generated for each case reducer function
export const { clearError, clearSuccess } = customerSlice.actions

export default customerSlice.reducer