import { Button, DatePicker, Image, Input, Modal } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CountriesDropdown from '../CountriesDropdown';
import dayjs from 'dayjs';
import CampaignModeDropdown from './CampaignModeDropdown';
import CampaignStatusDropdown from './CampaignStatusDropdown';
import CustomersDropdown from '../CustomersDropdown';
import CampaignTypesDropdown from '../CampaignTypesDropdown';
import VideoDurationDropdown from '../VideoDurationDropdown';
import { createCampaign, updateCampaign } from '../../redux/apis/campaignApi';
import { toast } from 'react-toastify';


const EditCreateCampaign = ({ state, open, setOpen, campaign }) => {

    
    const dispatch = useDispatch()
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState()
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            campaign_name: campaign?.campaign_name || null,
            campaign_actors: campaign?.campaign_actors || null,
            sample_of_work: campaign?.sample_of_work || null,
            sample_of_pirated_url: campaign?.sample_of_pirated_url || null,
            number_of_seasons: campaign?.number_of_seasons,
            campaign_duration: campaign?.campaign_duration || null,
            campaign_mode: campaign?.campaign_mode || null,
            campaign_loa_dropbox_url: campaign?.campaign_loa_dropbox_url || null,
            campaign_release_date: campaign?.campaign_release_date || null,
            campaign_start_date: campaign?.campaign_start_date || null,
            campaign_end_date: campaign?.campaign_end_date || null,
            campaign_rights_owner: campaign?.campaign_rights_owner || null,
            customer: campaign?.customer?.id || null,
            campaign_status: campaign?.campaign_status === true ? true : campaign?.campaign_status === false ? false : "",
            campaign_mode: campaign?.campaign_mode || null,
            campaign_type: campaign?.campaign_type || null,
            country: campaign?.country || null,
            number_of_episodes: campaign?.number_of_episodes,
            ownership_territory: campaign?.ownership_territory || null,
            policy: campaign?.policy || null,
            take_down_duration: campaign?.take_down_duration || null,
        },
        validationSchema: Yup.object({
            campaign_name: Yup.string().required(`Please Enter Campaign Name`),
            customer: Yup.string().required(`Please Choose Customer`),
            campaign_type: Yup.string().required(`Please Choose Campaign Type`),
            campaign_status: Yup.string().required(`Please Choose Campaign Status`),
            country: Yup.string().required(`Please Choose Country`),
            number_of_seasons: Yup.number().required(`Please Enter Number Of Seasons`),
            campaign_start_date: Yup.date().required(`Please Choose Campaign Start Date`),
            campaign_end_date: Yup.date().required(`Please Choose Campaign End Date`),
            campaign_mode: Yup.string().required(`Please Choose Campaign Mode`),
            sample_of_work: Yup.string().nullable(),
            sample_of_pirated_url: Yup.string().url().nullable(),
            campaign_actors: Yup.string().nullable(),
            campaign_loa_dropbox_url: Yup.string().nullable(),
            campaign_release_date: Yup.date().nullable(),
            number_of_episodes: Yup.number().nullable(),
            campaign_rights_owner: Yup.string().nullable(),
            ownership_territory: Yup.string().required(`Please Enter Ownership Territory`),
            policy: Yup.string().required(`Please Enter Policy`),
            take_down_duration: Yup.string().required(`Please Enter Take Down Duration`),
        }),
        onSubmit: (values) => {
            const formData = new FormData()
            Object.entries(values).forEach(([key, val]) => {
                if (val !== null && val !== undefined) {
                    formData.append(key, val)
                }
            }
            )
            if (file) {
                formData.append('campaign_poster_path', file)
            }

            if (state === "edit") {
                dispatch(updateCampaign({ data: formData, id: campaign?.id }))
            }
            if (state === "create") {
                dispatch(createCampaign(formData))
            }
            if (Object.keys(validation.errors).length === 0) {
                setOpen(false)
            }



        }
    });


    useEffect(() => {
        if (!file) {
            return
        }
        const objectUrl = URL.createObjectURL(file)
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [file])


    return (
        <Modal
            className='customModal'
            open={open}
            width={'55vw'}
            title={state === "edit" ? "Edit Campaign" : "Create Campaign"}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={() => {
                    if (Object.keys(validation.errors).length !== 0) {
                        Object.keys(validation.errors).map((key) => {
                            toast.error(validation.errors[key]);
                        });
                        return;
                    }
                    validation.handleSubmit();
                }}>
                    Submit
                </Button>
            ]}
        >

            <div className='flex justify-between'>

                <div className=" mb-3 w-[45%]">
                    <label className="" >Campaign Name</label>
                    <Input
                        type="text"
                        name='campaign_name'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.campaign_name}
                    />
                    {validation.touched.campaign_name && validation.errors.campaign_name ? (
                        <p className='text-danger'>{validation.errors.campaign_name}</p>
                    ) : null}
                </div>
                <div className=" mb-3 w-[45%]">
                    <label className="" >Campaign Actors</label>
                    <Input
                        type="text"
                        name='campaign_actors'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.campaign_actors}
                    />
                    {validation.touched.campaign_actors && validation.errors.campaign_actors ? (
                        <p className='text-danger'>{validation.errors.campaign_actors}</p>
                    ) : null}
                </div>
            </div>

            <div className='flex justify-between'>

                <div className=" mb-3 w-[45%]">
                    <label className="" >Work Sample</label>
                    <Input
                        type="text"
                        name='sample_of_work'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.sample_of_work}
                    />
                    {validation.touched.sample_of_work && validation.errors.sample_of_work ? (
                        <p className='text-danger'>{validation.errors.sample_of_work}</p>
                    ) : null}
                </div>
                <div className=" mb-3 w-[45%]">
                    <label className="" >Pirated Url Sample</label>
                    <Input
                        type="text"
                        name='sample_of_pirated_url'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.sample_of_pirated_url}
                    />
                    {validation.touched.sample_of_pirated_url && validation.errors.sample_of_pirated_url ? (
                        <p className='text-danger'>{validation.errors.sample_of_pirated_url}</p>
                    ) : null}
                </div>
            </div>
            <div className='flex justify-between'>

                <div className=" mb-3 w-[45%]">
                    <label className="" >Number Of Seasons</label>
                    <Input
                        type="number"
                        name='number_of_seasons'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.number_of_seasons}
                    />
                    {validation.touched.number_of_seasons && validation.errors.number_of_seasons ? (
                        <p className='text-danger'>{validation.errors.number_of_seasons}</p>
                    ) : null}
                </div>
                <div className=" mb-3 w-[45%]">
                    <label className="" >Number Of Episodes</label>
                    <Input
                        type="number"
                        name='number_of_episodes'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.number_of_episodes}
                    />
                    {validation.touched.no_of_episodes && validation.errors.no_of_episodes ? (
                        <p className='text-danger'>{validation.errors.no_of_episodes}</p>
                    ) : null}
                </div>
            </div>

            <div className='flex justify-between'>
                <div className=" mb-3 w-[45%]">
                    <label className="" >Campaign LOA (dropbox URL)</label>
                    <Input
                        type="text"
                        name='campaign_loa_dropbox_url'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.campaign_loa_dropbox_url}
                    />
                    {validation.touched.campaign_loa_dropbox_url && validation.errors.campaign_loa_dropbox_url ? (
                        <p className='text-danger'>{validation.errors.campaign_loa_dropbox_url}</p>
                    ) : null}
                </div>

                <div className=" mb-3 w-[45%]">
                    <label className="" >Campaign Release Date</label>
                    <br />
                    <DatePicker
                        style={{ width: "100%" }}
                        name='campaign_release_date'
                        onChange={(e, date) => validation.setFieldValue("campaign_release_date", date)}
                        onBlur={validation.handleBlur}
                        value={validation.values.campaign_release_date ? dayjs(validation.values.campaign_release_date) : ""}
                    />
                    {validation.touched.campaign_release_date && validation.errors.campaign_release_date ? (
                        <p className='text-danger'>{validation.errors.campaign_release_date}</p>
                    ) : null}
                </div>
            </div>
            <div className='flex justify-between'>

                <div className=" mb-3 w-[45%]">
                    <label className="" >Campaign Start Date</label>
                    <br />
                    <DatePicker
                        style={{ width: "100%" }}
                        name='campaign_start_date'
                        onChange={(e, date) => validation.setFieldValue("campaign_start_date", date)}
                        onBlur={validation.handleBlur}
                        value={validation.values.campaign_start_date ? dayjs(validation.values.campaign_start_date) : ""}
                    />
                    {validation.touched.campaign_start_date && validation.errors.campaign_start_date ? (
                        <p className='text-danger'>{validation.errors.campaign_start_date}</p>
                    ) : null}
                </div>
                <div className=" mb-3 w-[45%]">
                    <label className="" >Campaign End Date</label>
                    <br />
                    <DatePicker
                        style={{ width: "100%" }}
                        name='campaign_end_date'
                        onChange={(e, date) => validation.setFieldValue("campaign_end_date", date)}
                        onBlur={validation.handleBlur}
                        value={validation.values.campaign_end_date ? dayjs(validation.values.campaign_end_date) : ""}
                    />
                    {validation.touched.campaign_end_date && validation.errors.campaign_end_date ? (
                        <p className='text-danger'>{validation.errors.campaign_end_date}</p>
                    ) : null}
                </div>
            </div>
            <div className='flex justify-between'>


                <div className=" mb-3 w-[45%]">
                    <label className="" >Campaign Rights Owner</label>
                    <Input
                        type="text"
                        name='campaign_rights_owner'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.campaign_rights_owner}
                    />
                    {validation.touched.campaign_rights_owner && validation.errors.campaign_rights_owner ? (
                        <p className='text-danger'>{validation.errors.campaign_rights_owner}</p>
                    ) : null}
                </div>
                <div className=" mb-3 w-[45%]">
                    <CampaignModeDropdown
                        onChange={validation.setFieldValue}
                        value={validation.values.campaign_mode}
                        onBlur={validation.handleBlur}
                    />
                    {validation.touched.campaign_mode && validation.errors.campaign_mode ? (
                        <p className='text-danger'>{validation.errors.campaign_mode}</p>
                    ) : null}
                </div>
            </div>
            <div className='flex justify-between'>


                <div className=" mb-3 w-[45%]">
                    <CampaignStatusDropdown
                        onChange={validation.setFieldValue}
                        value={validation.values.campaign_status}
                        onBlur={validation.handleBlur}
                    />
                    {validation.touched.campaign_status && validation.errors.campaign_status ? (
                        <p className='text-danger'>{validation.errors.campaign_status}</p>
                    ) : null}
                </div>
                <div className=" mb-3 w-[45%]">
                    <CustomersDropdown
                        onChange={e => { validation.setFieldValue("customer", e) }}
                        value={validation.values.customer}
                        onBlur={validation.handleBlur}
                    />
                    {validation.touched.customer && validation.errors.customer ? (
                        <p className='text-danger'>{validation.errors.customer}</p>
                    ) : null}
                </div>
            </div>
            <div className='flex justify-between'>


                <div className=" mb-3 w-[45%]">
                    <CountriesDropdown
                        onChange={validation.setFieldValue}
                        onBlur={validation.handleBlur}
                        value={validation.values.country}
                        validation={validation}
                    />
                    {validation.touched.country && validation.errors.country ? (
                        <p className='text-danger'>{validation.errors.country}</p>
                    ) : null}
                </div>
                <div className=" mb-3 w-[45%]">
                    <CampaignTypesDropdown
                        onChange={validation.setFieldValue}
                        onBlur={validation.handleBlur}
                        value={validation.values.campaign_type}
                    />
                    {validation.touched.campaign_type && validation.errors.campaign_type ? (
                        <p className='text-danger'>{validation.errors.campaign_type}</p>
                    ) : null}
                </div>
            </div>
            <div className='flex justify-between'>


                <div className=" mb-3 w-[45%]">
                    <VideoDurationDropdown
                        onChange={(e) => {
                            return validation.setFieldValue('take_down_duration', e);
                        }}
                        title={"Take Down Duration"}
                        onBlur={validation.handleBlur}
                        value={validation.values.take_down_duration}
                    />
                    {validation.touched.take_down_duration && validation.errors.take_down_duration ? (
                        <p className='text-danger'>{validation.errors.take_down_duration}</p>
                    ) : null}
                </div>
                <div className=" mb-3 w-[45%]">
                    <label className="" >Ownership Territory</label>
                    <Input
                        type="text"
                        name='ownership_territory'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ownership_territory}
                    />
                    {validation.touched.ownership_territory && validation.errors.ownership_territory ? (
                        <p className='text-danger'>{validation.errors.ownership_territory}</p>
                    ) : null}
                </div>
            </div>
            <div className='flex justify-between'>


                <div className=" mb-3 w-[45%]">
                    <label className="" >Campaign Policy</label>
                    <Input
                        type="text"
                        name='policy'
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.policy}
                    />
                    {validation.touched.policy && validation.errors.policy ? (
                        <p className='text-danger'>{validation.errors.policy}</p>
                    ) : null}
                </div>
                <div className=" mb-3 w-[45%]">
                    <label className="" >Upload Campaign Cover</label>
                    <div className='flex gap-4'>

                        <Input
                            type="file"
                            onChange={(e) => setFile(e.target.files[0])}
                            accept=".png, .jpg, .jpeg"
                        />
                        <Image
                            className=''
                            style={{ width: "50px" }}
                            src={preview ? preview : campaign?.campaign_poster_path}
                        />
                    </div>
                </div>
            </div>

        </Modal>
    )
}

export default EditCreateCampaign