import React, { useContext, useEffect, useState } from 'react'
import { AppSettings } from '../../config/app-settings';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../pages/loader';
import ReusableTable from '../../components/other/ReuseableTable';
import { listCampaign } from '../../redux/apis/campaignApi';
import EditCreateLOA from '../../components/campaigns/EditCreateLOA';
import { can } from '../../helpers/auth';
import NoPermission from '../pages/noPermission';
import { clearError, clearSuccess } from '../../redux/slices/campaignSlice';
import { toast } from 'react-toastify';

const CampaignsLoa = () => {
    const dispatch = useDispatch();
    const { campaigns, isLoading, error, success } = useSelector(state => state.campaign);
    const [edit, setEdit] = useState(false)
    const [create, setCreate] = useState(false)
    const [holder, setHolder] = useState()

    useEffect(() => {
        if (!campaigns || !campaigns?.[0]?.last_updated_by) {
            dispatch(listCampaign({
                expand: ['last_updated_by', 'customer'].join(','),
                page: '*',
                ordering: 'id',
                fields: 'id,campaign_name,campaign_loa_dropbox_url,last_updated_by.email,last_updated_by.first_name,last_updated_by.last_name'
            }));
        }
    }, []);

    const columns = [
        { title: "ID", dataIndex: "id", key: "id", sorter: true },
        {
            title: 'Campaign Name',
            dataIndex: 'campaign_name',
            key: 'campaign_name',
            sorter: true,
            search: true,
        },

        {
            title: 'Campaign LOA',
            dataIndex: 'campaign_loa_dropbox_url',
            key: 'campaign_loa_dropbox_url',
            sorter: true,
            search: true,
            width: "30%"
        },
        {
            title: 'Last Updated By',
            dataIndex: 'last_updated_by',
            key: 'last_updated_by',
            render: (text, record) => {
                const { last_updated_by } = record;
                return last_updated_by ? `${last_updated_by.first_name} ${last_updated_by.last_name}` : '';
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <div className='flex gap-2'>
                    <button className='btn btn-outline-theme' onClick={() => handleEdit(record)}>Edit</button>
                </div>
            ),
        },
    ];


    const handleEdit = (record) => {
        setHolder(record)
        setEdit(true)
    };


    const context = useContext(AppSettings);
    useEffect(() => {
        context.setAppHeaderNone(false);
        context.setAppSidebarNone(false);
        context.setAppContentClass('');
        context.setAppThemePanal(true)

        return function cleanUp() {
            context.setAppHeaderNone(true);
            context.setAppSidebarNone(true);
            context.setAppContentClass('p-0');
            context.setAppThemePanal(false)
        };
    }, []);

    useEffect(() => {
        if (!error && !isLoading) {
            return
        }
        toast.error(error, { onclose: () => { dispatch(clearError()) } })
    }, [error, isLoading])

    
    useEffect(() => {
        if (success) {
            toast.success("Updated successfuly", { onclose: () => { dispatch(clearSuccess()) } })
        }
    }, [success])

    if (!can("view_campaign")) {
        return <NoPermission />
    }

    return (
        <div className='w-full flex flex-col'>
            <Loader isLoading={isLoading} />
            {edit ? <EditCreateLOA state={"edit"} open={edit} setOpen={setEdit} campaign={holder} /> : null}
            {create ? <EditCreateLOA state={"create"} open={create} setOpen={setCreate} campaign={null} /> : null}
            <button className='btn btn-outline-theme ml-auto' onClick={() => setCreate(true)}>Add New Customer LOA</button>
            <br />
            {campaigns ?
                <ReusableTable
                    data={campaigns}
                    columns={columns}
                />
                : null
            }
        </div>
    )
}

export default CampaignsLoa