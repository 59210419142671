import { createSlice } from '@reduxjs/toolkit'
import { createPlatform, listPlatforms, updatePlatform } from '../apis/platformApi';


const initialState = {
  platforms: null,
  isLoading: false,
  error: null,
  success: false
}

export const platformSlice = createSlice({
  name: 'platform',
  initialState,
  reducers: {
    clearPlatform: (state) => {
      state.platforms = null;
    },
    clearError: (state) => {
      state.error = null
    },
    clearSuccess: (state) => {
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listPlatforms.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listPlatforms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.platforms = action.payload
      })
      .addCase(listPlatforms.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(createPlatform.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createPlatform.fulfilled, (state, action) => {
        state.isLoading = false;
        state.platforms = [action.payload, ...state.platforms]
        state.success = true
      })
      .addCase(createPlatform.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(updatePlatform.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePlatform.fulfilled, (state, action) => {
        state.isLoading = false;
        state.platforms = state.platforms.map(x => x.id === action.payload.id ? action.payload : x)
        state.success = true
      })
      .addCase(updatePlatform.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
    //------------
  },
})

// Action creators are generated for each case reducer function
export const { clearPlatform, clearError, clearSuccess } = platformSlice.actions

export default platformSlice.reducer