import { Select } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listCampaignsType } from '../redux/apis/campaignApi'
import { clearError } from '../redux/slices/campaignSlice'
import { toast } from 'react-toastify'

const CampaignTypesDropdown = ({ onChange, onBlur, value }) => {
    const dispatch = useDispatch()
    const { campaignsType, isLoading, error } = useSelector(state => state.campaign)
    useEffect(() => {
        if (!campaignsType) {

            dispatch(listCampaignsType({
                page: '*',
                ordering: 'type',
                fields: 'id,type'
            }))
        }
    }, [])
    
	useEffect(()=>{
        if ( !error && !isLoading) {
            return
        }
        toast.error(error,{ onclose: ()=>{dispatch(clearError())}})
    },[error, isLoading])


    return (
        <div className=" mb-3">
            <label className="" >Campaign Type</label>
            <br />
            <Select
                name='campaign_type'
                showSearch
                style={{
                    width: "100%"
                }}
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                defaultValue={value}
                value={value}
                onChange={e => onChange("campaign_type", e)}
                onBlur={onBlur}
                loading={isLoading}
                options={campaignsType?.map(x => {
                    return { label: x.type, value: x.id }
                })}
            />
        </div>
    )
}

export default CampaignTypesDropdown