import { createSlice } from '@reduxjs/toolkit'
import { backgroundTasks, dailyDetectedReportedRemoved, detectedReportedRemoved, numberOfCampaigns, opsPerformance, perCustomerDetectedReportedRemoved, perPlatformMonthDetectedReportedRemoved, reportsOpsPerformance, topCampaignsDetectedReportedRemoved, topRemovals } from '../apis/analyticsApi';


const initialState = {
  campaignsCount: null,
  detectedreportedRemovedCampaigns: null,
  perCustomerDetectedReportedRemovedCampaigns: null,
  perPlatformMonthDetectedReportedRemovedCampaigns: null,
  topDetectedReportedRemovedCampaigns: null,
  dailyDetectedReportedRemovedCampaigns: null,
  topRemovalsCampaigns: null,
  opsPerformanceData: null,
  reportsOpsPerformanceData: null,
  backgroundTasksData: null,
  isLoading: {
    numberOfCampaigns: false,
    detectedReportedRemoved: false,
    perCustomerDetectedReportedRemoved: false,
    perPlatformMonthDetectedReportedRemoved: false,
    topCampaignsDetectedReportedRemoved: false,
    dailyDetectedReportedRemoved: false,
    topRemovals: false,
    opsPerformance: false,
    reportsOpsPerformance: false,
    backgroundTasks: false
  },
  error: null,
  success: false
}

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    clearError:(state)=>{
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(numberOfCampaigns.pending, (state) => {
        state.isLoading.numberOfCampaigns = true;
        state.error = null;
      })
      .addCase(numberOfCampaigns.fulfilled, (state, action) => {
        state.isLoading.numberOfCampaigns = false;
        state.campaignsCount = action.payload
      })
      .addCase(numberOfCampaigns.rejected, (state, action) => {
        state.isLoading.numberOfCampaigns = false;
        state.error = action.error.message;
      })
      //-----------------
      .addCase(detectedReportedRemoved.pending, (state) => {
        state.isLoading.detectedReportedRemoved = true;
        state.error = null;
      })
      .addCase(detectedReportedRemoved.fulfilled, (state, action) => {
        state.isLoading.detectedReportedRemoved = false;
        state.detectedreportedRemovedCampaigns = action.payload
      })
      .addCase(detectedReportedRemoved.rejected, (state, action) => {
        state.isLoading.detectedReportedRemoved = false;
        state.error = action.error.message;
      })
      //-----------------
      .addCase(perCustomerDetectedReportedRemoved.pending, (state) => {
        state.isLoading.perCustomerDetectedReportedRemoved = true;
        state.error = null;
      })
      .addCase(perCustomerDetectedReportedRemoved.fulfilled, (state, action) => {
        state.isLoading.perCustomerDetectedReportedRemoved = false;
        state.perCustomerDetectedReportedRemovedCampaigns = action.payload
      })
      .addCase(perCustomerDetectedReportedRemoved.rejected, (state, action) => {
        state.isLoading.perCustomerDetectedReportedRemoved = false;
        state.error = action.error.message;
      })
      //-----------------
      .addCase(perPlatformMonthDetectedReportedRemoved.pending, (state) => {
        state.isLoading.perPlatformMonthDetectedReportedRemoved = true
        state.error = null;
      })
      .addCase(perPlatformMonthDetectedReportedRemoved.fulfilled, (state, action) => {
        state.isLoading.perPlatformMonthDetectedReportedRemoved = false
        state.perPlatformMonthDetectedReportedRemovedCampaigns = action.payload
      })
      .addCase(perPlatformMonthDetectedReportedRemoved.rejected, (state, action) => {
        state.isLoading.perPlatformMonthDetectedReportedRemoved = false;
        state.error = action.error.message;
      })
      //-----------------
      .addCase(topCampaignsDetectedReportedRemoved.pending, (state) => {
        state.isLoading.topCampaignsDetectedReportedRemoved = true;
        state.error = null;
      })
      .addCase(topCampaignsDetectedReportedRemoved.fulfilled, (state, action) => {
        state.isLoading.topCampaignsDetectedReportedRemoved = false;
        state.topDetectedReportedRemovedCampaigns = action.payload
      })
      .addCase(topCampaignsDetectedReportedRemoved.rejected, (state, action) => {
        state.isLoading.topCampaignsDetectedReportedRemoved = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(dailyDetectedReportedRemoved.pending, (state) => {
        state.isLoading.dailyDetectedReportedRemoved = true;
        state.error = null;
      })
      .addCase(dailyDetectedReportedRemoved.fulfilled, (state, action) => {
        state.isLoading.dailyDetectedReportedRemoved = false;
        state.dailyDetectedReportedRemovedCampaigns = action.payload
      })
      .addCase(dailyDetectedReportedRemoved.rejected, (state, action) => {
        state.isLoading.dailyDetectedReportedRemoved = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(topRemovals.pending, (state) => {
        state.isLoading.topRemovals = true;
        state.error = null;
      })
      .addCase(topRemovals.fulfilled, (state, action) => {
        state.isLoading.topRemovals = false;
        state.topRemovalsCampaigns = action.payload
      })
      .addCase(topRemovals.rejected, (state, action) => {
        state.isLoading.topRemovals = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(opsPerformance.pending, (state) => {
        state.isLoading.opsPerformance = true;
        state.error = null;
      })
      .addCase(opsPerformance.fulfilled, (state, action) => {
        state.isLoading.opsPerformance = false;
        state.opsPerformanceData = action.payload
      })
      .addCase(opsPerformance.rejected, (state, action) => {
        state.isLoading.opsPerformance = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(reportsOpsPerformance.pending, (state) => {
        state.isLoading.reportsOpsPerformance = true;
        state.error = null;
      })
      .addCase(reportsOpsPerformance.fulfilled, (state, action) => {
        state.isLoading.reportsOpsPerformance = false;
        state.reportsOpsPerformanceData = action.payload
      })
      .addCase(reportsOpsPerformance.rejected, (state, action) => {
        state.isLoading.reportsOpsPerformance = false;
        state.error = action.error.message;
      })
      //------------
      .addCase(backgroundTasks.pending, (state) => {
        state.isLoading.backgroundTasks = true;
        state.error = null;
      })
      .addCase(backgroundTasks.fulfilled, (state, action) => {
        state.isLoading.backgroundTasks = false;
        state.backgroundTasksData = action.payload
      })
      .addCase(backgroundTasks.rejected, (state, action) => {
        state.isLoading.backgroundTasks = false;
        state.error = action.error.message;
      })
    //------------
  },
})

// Action creators are generated for each case reducer function
export const { clearError } = analyticsSlice.actions

export default analyticsSlice.reducer