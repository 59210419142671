import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import  analyticsSlice  from "./slices/analyticsSlice";
import  campaignSlice  from "./slices/campaignSlice";
import  utilsSlice  from "./slices/utilsSlice";
import  videoSlice  from "./slices/videoSlice";
import  customerSlice  from "./slices/customerSlice";
import  permissionsSlice  from "./slices/permissionsSlice";
import  platformSlice  from "./slices/platformSlice";
import  whitelistSlice  from "./slices/whitelistSlice";

export default combineReducers({
    auth: authSlice,
    analytics:analyticsSlice,
    campaign:campaignSlice,
    customers:customerSlice,
    permissions:permissionsSlice,
    platform:platformSlice,
    utils:utilsSlice,
    video:videoSlice,
    whitelist:whitelistSlice,
})