import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select } from 'antd'
import { listContentTypes } from '../redux/apis/authApi'
import { clearError } from '../redux/slices/authSlice'
import { toast } from 'react-toastify'

const ContentTypesDropdown = ({ onChange, onBlur, value, multi = true }) => {
    const dispatch = useDispatch()
    const { contentTypes, isLoading, error } = useSelector(state => state.auth)
    let options = []

    useEffect(() => {
        if (!contentTypes) {

            dispatch(listContentTypes({
                page: '*',
                ordering: 'app_label',
                fields: 'id,app_label,model',
            }))
        }
    }, [])
    if (contentTypes) {
        options = contentTypes.map((item) => {
            return {
                label: item.app_label + " - " + item.model,
                value: item.id
            }
        })
    }

    useEffect(() => {
        if (!error && !isLoading) {
            return
        }
        toast.error(error, { onclose: () => { dispatch(clearError()) } })
    }, [error, isLoading])

    return (
        <div className=" mb-3">
            <label className="" >Content Type</label>
            <br />
            <Select
                mode={multi ? "multiple" : ""}
                allowClear
                style={{
                    width: '100%',
                }}
                loading={isLoading}
                placeholder="Please select"
                onBlur={onBlur}
                value={value}
                onChange={(e) => onChange("content_type", e)}
                options={options}
            />
        </div>
    )
}

export default ContentTypesDropdown