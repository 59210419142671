import { Button, Image, Input, Modal } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { createCustomer, updateCustomer } from '../../redux/apis/customerApi';
import CountriesDropdown from '../CountriesDropdown';
import { toast } from 'react-toastify';

const EditCreateCustomer = ({ state, open, setOpen, record }) => {
    const dispatch = useDispatch()
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState("")
    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            user: record?.user || "",
            customer_name: record?.customer_name || "",
            customer_business: record?.customer_business || "",
            customer_phone: record?.customer_phone || "",
            customer_address: record?.customer_address || "",
            country: record?.country?.id || "",
            customer_logo_path: ""
        },

        validationSchema: Yup.object({
            customer_name: Yup.string().required("Please Enter Customer Name"),
            customer_business: Yup.string().required("Please Enter Customer Business"),
            customer_phone: Yup.string().nullable(),
            customer_address: Yup.string().nullable(),
            country: Yup.string().required("Please Choose Country"),
            customer_logo_path: Yup.string().required("Please upload Logo image"),
        }),
        onSubmit: (values) => {
            const formData = new FormData();
            for (let key in values) {
                formData.append(key, values[key]);
            }
            
            if (state === "edit") {
                dispatch(updateCustomer({ data: formData, id: record?.id }))
            }
            if (state === "create") {
                dispatch(createCustomer(formData))
            }
            if (Object.keys(validation.errors).length === 0) {
                setOpen(false)
            }

        }
    });
    useEffect(() => {
        if (!file) {
            return
        }
        validation.setFieldValue("customer_logo_path", file, file?.name);
        const objectUrl = URL.createObjectURL(file)
        setPreview(objectUrl)
        return () => URL.revokeObjectURL(objectUrl)
    }, [file])
    return (
        <Modal
            className='customModal'
            open={open}
            title={state === "edit" ? "Edit Customer" : "Create Customer"}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="back" onClick={() => setOpen(false)}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={() => {
                    if (Object.keys(validation.errors).length !== 0) {
                        Object.keys(validation.errors).map((key) => {
                            toast.error(validation.errors[key]);
                        });
                        return;
                    }
                    validation.handleSubmit();
                }}>
                    Submit
                </Button>
            ]}
        >

            <div className=" mb-3">
                <label className="" >Customer Name</label>
                <Input
                    type="text"
                    name='customer_name'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.customer_name}
                />
                {validation.touched.customer_name && validation.errors.customer_name ? (
                    <p className='text-danger'>{validation.errors.customer_name}</p>
                ) : null}
            </div>
            <div className=" mb-3">
                <label className="" >Customer Business</label>
                <Input
                    type="text"
                    name='customer_business'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.customer_business}
                />
                {validation.touched.customer_business && validation.errors.customer_business ? (
                    <p className='text-danger'>{validation.errors.customer_business}</p>
                ) : null}
            </div>
            <div className=" mb-3">
                <label className="" >Customer Contact Number</label>
                <Input
                    type="text"
                    name='customer_phone'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.customer_phone}
                />
                {validation.touched.customer_phone && validation.errors.customer_phone ? (
                    <p className='text-danger'>{validation.errors.customer_phone}</p>
                ) : null}
            </div>
            <div className=" mb-3">
                <label className="" >Customer Address</label>
                <Input
                    type="text"
                    name='customer_address'
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.customer_address}
                />
                {validation.touched.customer_address && validation.errors.customer_address ? (
                    <p className='text-danger'>{validation.errors.customer_address}</p>
                ) : null}
            </div>
            <div>

                <CountriesDropdown
                    onChange={validation.setFieldValue}
                    onBlur={validation.handleBlur}
                    value={validation.values.country}
                    validation={validation}
                />
                {validation.touched.country && validation.errors.country ? (
                    <p className='text-danger'>{validation.errors.country}</p>
                ) : null}
            </div>
            <div className=" mb-3">
                <label className="" >Upload Profile Picture</label>
                <div className='flex gap-4'>

                    <Input
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                        accept=".png, .jpg, .jpeg"
                        onBlur={validation.handleBlur}
                        required
                    />
                    <Image
                        className='border-1 rounded-full'
                        style={{ width: "50px" }}
                        src={preview ? preview : record?.customer_logo_path}
                    />
                </div>
                {validation.touched.customer_logo_path && validation.errors.customer_logo_path ? (
                    <p className='text-danger'>{validation.errors.customer_logo_path}</p>
                ) : null}
            </div>
        </Modal>
    )
}

export default EditCreateCustomer