import React,{useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserId } from '../../helpers/auth'
import { createVideo } from '../../redux/apis/videosApi'
import { Card, CardBody, CardFooter, CardHeader } from '../other/card/card'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import UsersDropdown from '../UsersDropdown'
import CampaignDropdown from '../CampaignDropdown'
import VideoDurationDropdown from '../VideoDurationDropdown'
import Loader from '../../pages/pages/loader'
import VideoTypesDropdown from '../VideoTypesDropdown'
import { DatePicker, Input } from 'antd'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { clearLinkInsertionError } from '../../redux/slices/videoSlice';


const TextAreaInsertion = ({ platformId, platform_name, matchText = [], isContentID = false }) => {
    const dispatch = useDispatch()
    const { isLoading, error } = useSelector(state => state.video)

    const atLeastOneMatches = (url, arr) => {
        for (let item of arr) {
            if (url.match(item)) {
                return true;
            }
        }
        return false;
    }
    const getCurrentFormattedDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getUTCHours()).padStart(2, '0');
        const minutes = String(now.getUTCMinutes()).padStart(2, '0');
        const seconds = String(now.getUTCSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}+00`;
    };

    const validation = useFormik({
        initialValues: {
            campaign: '',
            url: '',
            video_total_views: '',
            channel_id: '',
            report_date: null,
            remove_date: null,
            keyword: '',
            comments: "",
            user: getUserId(),
        },
        validationSchema: Yup.object().shape({
            campaign: Yup.string().required('Campaign is required'),
            video_duration: Yup.string().required('video_duration is required'),
            url: Yup.string().required('URLs are required'),
            channel_id: isContentID ? Yup.string().nullable() : Yup.string().required('Channel ID is required'),
            video_total_views: Yup.string().required('Video Total Viewers are required'),
            report_date: isContentID ? Yup.date().required('Report Date is required') : Yup.date().nullable(),
            remove_date: Yup.date().nullable(),
        }),
        onSubmit: async (values) => {
            if (!atLeastOneMatches(values.url, matchText)) {
                toast.error(`Invalid URL: ${values.url}`);
                return;
            }
            const data = {
                url: values.url,
                keyword: values.keyword,
                review_date: values.report_date ? values.report_date : getCurrentFormattedDate(),
                channel_id: values.channel_id,
                report_date: values.report_date ? values.report_date.split('T').join(' ') + ':00+00' : null,
                remove_date: values.remove_date ? values.remove_date.split('T').join(' ') + ':00+00' : null,
                link_type: 'related',
                platform: platformId,
                campaign: values.campaign,
                inserted_by: values.user,
                video_data: {
                    video_total_views: values.video_total_views,
                    video_duration: values.video_duration,
                    video_type_id: values.video_type,
                    comments: values.comments
                }
            }
            if(isContentID){
                data["is_content_id"] = true
            }
            let invalidData = Object.keys(validation.errors).length
            if (invalidData > 0) {
                toast.error(`Invalid: ${invalidData.length} \nPlease insert ${platform_name} URLs \nErrors: ${invalidData.join('\n')}`)
            } else {


                dispatch(createVideo(data))
                toast.info("Adding video Url, Please wait.")
            }
        },
    });
    
    useEffect(()=>{
        if (Object.keys(validation.errors).length === 0 && !error && !isLoading) {
            validation.resetForm();
            return
        }
        toast.error(error,{ onclose: ()=>{dispatch(clearLinkInsertionError())}})
    },[error, isLoading])

    return (
        <div>
            <Loader isLoading={isLoading} />
            <Card>
                <CardHeader>
                    Link Insertion
                </CardHeader>
                <CardBody>
                    <div>
                        <UsersDropdown
                            onBlur={validation.handleBlur}
                            onChange={validation.setFieldValue}
                            value={validation.values.user}
                            filters={{
                                customer__isnull: false
                            }}
                            insertion={true}
                            title={"Inserter"}
                            className='customSelect'
                        />
                        {validation.touched.user && validation.errors.user ? (
                            <p className='text-danger'>{validation.errors.user}</p>
                        ) : null}
                    </div>
                    <div>
                        <CampaignDropdown
                            onBlur={validation.handleBlur}
                            onChange={validation.setFieldValue}
                            value={validation.values.campaign}
                            activeCampaignsOnly={true}
                            className='customSelect'
                        />
                        {validation.touched.campaign && validation.errors.campaign ? (
                            <p className='text-danger'>{validation.errors.campaign}</p>
                        ) : null}
                    </div>
                    <div>

                        <VideoDurationDropdown
                            onBlur={validation.handleBlur}
                            onChange={(e) => {
                                return validation.setFieldValue('video_duration', e);
                            }}
                            title={"Video Duration"}
                            value={validation.values.video_duration}
                            className='customSelect'
                        />
                        {validation.touched.take_down_duration && validation.errors.take_down_duration ? (
                            <p className='text-danger'>{validation.errors.take_down_duration}</p>
                        ) : null}
                    </div>
                    <div>
                        <VideoTypesDropdown
                            onBlur={validation.handleBlur}
                            onChange={(e) => {
                                return validation.setFieldValue('video_type', e);
                            }}
                            title={"Video Type"}
                            value={validation.values.video_type}
                            className='customSelect'
                        />
                        {validation.touched.take_down_type && validation.errors.take_down_type ? (
                            <p className='text-danger'>{validation.errors.take_down_type}</p>
                        ) : null}
                    </div>
                    <div className=" mb-3 customInput">
                        <label className="" >URL</label>
                        <Input
                            type="text"
                            name='url'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.url}
                        />
                        {validation.touched.url && validation.errors.url && (
                            <p className='text-danger'>{validation.errors.url}</p>
                        )}
                    </div>
                    <div className=" mb-3 customInput">
                        <label className="" >Channel ID</label>
                        <Input
                            type="text"
                            name='channel_id'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.channel_id}
                        />
                        {validation.touched.channel_id && validation.errors.channel_id && (
                            <p className='text-danger'>{validation.errors.channel_id}</p>
                        )}
                    </div>
                    <div className=" mb-3 customInput">
                        <label className="" >Video Total Viewers</label>
                        <Input
                            type="text"
                            name='video_total_views'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.video_total_views}
                        />
                        {validation.touched.video_total_views && validation.errors.video_total_views && (
                            <p className='text-danger'>{validation.errors.video_total_views}</p>
                        )}
                    </div>
                    <div className=" mb-3 customInput">
                        <label className="" >Keyword</label>
                        <Input
                            type="text"
                            name='keyword'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.keyword}
                        />
                        {validation.touched.keyword && validation.errors.keyword && (
                            <p className='text-danger'>{validation.errors.keyword}</p>
                        )}
                    </div>
                    <div className=" mb-3 customInput">
                        <label className="" >Comments</label>
                        <Input
                            type="text"
                            name='comments'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.comments}
                        />
                        {validation.touched.comments && validation.errors.comments && (
                            <p className='text-danger'>{validation.errors.comments}</p>
                        )}
                    </div>
                    <div className=" mb-3 customeDate">
                        <label className="" >Report Date</label>
                        <br />
                        <DatePicker
                            name='report_date'
                            onChange={(e, date) => validation.setFieldValue("report_date", date)}
                            onBlur={validation.handleBlur}
                            value={validation.values.report_date ? dayjs(validation.values.report_date) : ""}
                        />
                        {validation.touched.report_date && validation.errors.report_date ? (
                            <p className='text-danger'>{validation.errors.report_date}</p>
                        ) : null}
                    </div>
                    <div className=" mb-3 customeDate">
                        <label className="" >Remove Date</label>
                        <br />
                        <DatePicker
                            name='remove_date'
                            onChange={(e, date) => validation.setFieldValue("remove_date", date)}
                            onBlur={validation.handleBlur}
                            value={validation.values.remove_date ? dayjs(validation.values.remove_date) : ""}
                        />
                        {validation.touched.remove_date && validation.errors.remove_date ? (
                            <p className='text-danger'>{validation.errors.remove_date}</p>
                        ) : null}
                    </div>
                </CardBody>
                <CardFooter>
                    <button type="submit" className="btn btn-outline-theme" onClick={(e) => validation.handleSubmit()}
                        disabled={isLoading}
                    >
                        Submit
                    </button>
                </CardFooter>
            </Card>
        </div>
    )
}

export default TextAreaInsertion