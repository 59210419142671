import React, { useContext, useEffect, useState } from 'react';
import { AppSettings } from '../../config/app-settings';
import { useDispatch, useSelector } from 'react-redux';
import { listCampaign } from '../../redux/apis/campaignApi';
import CampaignCard from '../../components/other/card/campaignCard';
import { Card, CardBody } from '../../components/other/card/card';
import Loader from '../pages/loader';

const CampaignsAnalytics = () => {
    const dispatch = useDispatch();
    const { campaigns, isLoading } = useSelector(state => state.campaign);
    const [search, setSearch] = useState("");
    const [active, setActive] = useState("all");
    const [filteredCampaigns, setFilteredCampaigns] = useState([]);

    useEffect(() => {
        if (!campaigns) {
            dispatch(listCampaign({
                page: '*',
                expand: ['customer'],
                fields: [
                    'id',
                    'campaign_name',
                    'campaign_status',
                    'campaign_poster_path',
                    'customer.customer_logo_path'
                ].join(','),
                ordering: 'campaign_name'
            }));
        }
    }, [dispatch, campaigns]);

    useEffect(() => {
        setFilteredCampaigns(campaigns);
    }, [campaigns]);

    useEffect(() => {
        const filtered = campaigns?.filter(item => {
            const matchesSearch = item?.campaign_name?.toLowerCase().includes(search.toLowerCase());
    
            const matchesActive = active === 'all' 
                || (active === 'active' && item.campaign_status === true) 
                || (active === 'inactive' && item.campaign_status === false);
    
            return matchesSearch && matchesActive;
        });
        setFilteredCampaigns(filtered);
    }, [search, active, campaigns]);
    

    const context = useContext(AppSettings);
    useEffect(() => {
        context.setAppHeaderNone(false);
        context.setAppSidebarNone(false);
        context.setAppContentClass('');
        context.setAppThemePanal(true);

        return function cleanUp() {
            context.setAppHeaderNone(true);
            context.setAppSidebarNone(true);
            context.setAppContentClass('p-0');
            context.setAppThemePanal(false);
        };
    }, [context]);

    if (filteredCampaigns?.length === 0) {
        return (
            <div style={{ textAlign: "center", margin: "20px", width: "100%" }}>
                <h3>No campaigns found.</h3>
            </div>
        );
    }

    return (
        <div>
            <Loader isLoading={isLoading} />

            <div className='flex justify-between'>
                <div className="form-group mb-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => { setSearch(e.target.value) }}
                    />
                </div>
                <div className="mb-3 flex gap-3">
                    <button
                        className={`btn ${active === 'all' ? 'btn-outline-theme' : 'btn-outline-secondary'}`}
                        onClick={() => setActive('all')}
                    >
                        All
                    </button>
                    <button
                        className={`btn ${active === 'active' ? 'btn-outline-theme' : 'btn-outline-secondary'}`}
                        onClick={() => setActive('active')}
                    >
                        Active
                    </button>
                    <button
                        className={`btn ${active === 'inactive' ? 'btn-outline-theme' : 'btn-outline-secondary'}`}
                        onClick={() => setActive('inactive')}
                    >
                        Inactive
                    </button>
                </div>
            </div>
            <Card>
                <CardBody className="flex flex-wrap gap-8 justify-center">
                    {filteredCampaigns?.map(item => (
                        <CampaignCard type={"active"} item={item} key={item?.id} />
                    ))}
                </CardBody>
            </Card>
        </div>
    );
};

export default CampaignsAnalytics;
